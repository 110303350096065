import { ReactNode } from "react";
export const abkuerzungen_german: {
  id: string;
  name: ReactNode;
  description: ReactNode;
}[] = [
  {
    id: "BIM",
    name: "BIM",
    description:
      "Building Information Modeling: Arbeitsmethodik, bei der ein 3D-visualisiertes Gebäudemodell mit alphanumerischen Kontextinformationen und Dokumenten über den gesamten Lebenszyklus durch die verschiedenen Projektakteure angereichert wird. Ziel ist es, ein gemeinsames Informations- und Kommunikationsumfeld für alle Stakeholder rund um das Bauwerk zu schaffen.",
  },
  {
    id: "CoE-Prinzip",
    name: "CoE-Prinzip",
    description:
      "Comply-or-Explain-Prinzip: Nach dem Comply-or-Explain-Prinzip zeigen Akteure an, ob sie mit den jeweiligen rechtlichen Vorgaben einverstanden sind und sie entsprechend umsetzen (comply) oder ob sie sie nicht befolgen. Falls sie sie nicht befolgen, müssen sie die Entscheidung ausreichend begründen (explain).",
  },
  {
    id: "CO2e",
    name: (
      <span>
        CO<sub>2</sub>e
      </span>
    ),
    description: (
      <>
        <span>
          CO<sub>2</sub>-Äquivalente
        </span>
        : siehe unter „Zentrale Begriffe“
      </>
    ),
  },
  {
    id: "CRREM",
    name: "CRREM",
    description:
      "Carbon Risk Real Estate Monitor: Tool, mit dem für einzelne Immobilien oder auch das gesamte Portfolio die „grauen“ Emissionen und die Energieverbräuche erfasst und in Benchmarks zu allgemeinen Entwicklungsvorgaben oder Vergleichsportfolien beurteilt werden können. Es lassen sich damit zudem Indikatoren zum Risikomanagement für das Berichtswesen und sonstige Bekanntmachungen ableiten.",
  },
  {
    id: "CSR",
    name: "CSR",
    description:
      "Corporate Social Responsibility: Managementansatz einer Unternehmensethik. Das Unternehmen versteht sich als Teil der Gesellschaft und verantwortet durch ökologisch, sozial aber auch ökonomisch nachhaltiges Unternehmensverhalten den Wohlstand und das Wohlgefühl einer lebenswerten Gesellschaft.",
  },
  {
    id: "CSRD",
    name: "CSRD",
    description:
      "Corporate Sustainability Reporting Directive: Die Richtlinie 2022/2464 der EU richtet sich insbesondere an alle Unternehmen und Institutionen von öffentlichem Interesse. Sie ist ähnlich der Offenlegungsverordnung für nachhaltige Finanzen gem. EU-Taxonomie zu verstehen. Der Bericht muss über die Auswirkungen der Tätigkeiten des Unternehmens auf Nachhaltigkeitsaspekte sowie das Verständnis der Auswirkungen von Nachhaltigkeitsaspekten auf Geschäftsverlauf, Geschäftsergebnis und Lage des Unternehmens informieren.",
  },
  {
    id: "CSR-RUG",
    name: "CSR-RUG",
    description:
      "CSR-Richtlinie-Umsetzungsgesetz: siehe unter „Zentrale Begriffe“",
  },
  {
    id: "DNK",
    name: "DNK",
    description:
      "Deutscher Nachhaltigkeitskodex: Kriterien-Datenbank zum Aufbau einer Nachhaltigkeitsstrategie und -berichterstattung in großen und kleinen, öffentlichen und privaten Unternehmen, die ihre Stakeholder über ihre Nachhaltigkeitsleistungen informieren wollen. ",
  },
  {
    id: "DNSH-Prinzip",
    name: "DNSH-Prinzip",
    description: (
      <p>
        Do-No-Significant-Harm-Prinzip: Das DNSH-Prinzip wird mit der Verordnung
        2020/852 der EU – auch Sustainable Finance Taxonomie genannt – als ein
        Prinzip erklärt, nach dem eine Wirtschaftsaktivität bzw.
        unternehmerisches Handeln taxonomiekonform ist, wenn dabei ein
        wesentlicher Beitrag zu mindestens einem von insgesamt sechs
        Umweltzielen geleistet wird, ohne dabei wiederum ein anderes dieser
        sechs Ziele zu verletzen. Die Umweltziele der Taxonomie sind:
        (1)&nbsp;Klimaschutz, (2)&nbsp;Anpassung an den Klimawandel,
        (3)&nbsp;nachhaltige Nutzung von Wasserressourcen, (4)&nbsp;Wandel zu
        einer Kreislaufwirtschaft, (5)&nbsp;Vermeidung von Verschmutzung und
        (6)&nbsp;Schutz von Ökosystemen und Biodiversität.
      </p>
    ),
  },
  {
    id: "ESEF",
    name: "ESEF",
    description: (
      <span>
        Das European Single Electronic Format ist ein EU-weit einheitliches
        elektronisches Berichtsformat, das Emittenten, deren Wertpapiere
        innerhalb der EU notieren, seit 2020 in der
        Unternehmens&shy;berichterstattung anwenden müssen. Ziel des digitalen
        Berichtsformats ist es, die Zugänglichkeit, Analyse und Vergleichbarkeit
        der jährlichen Finanzberichte zu erleichtern.
      </span>
    ),
  },
  {
    id: "ESG",
    name: "ESG",
    description:
      "Environment, Social, Governance: Managementansatz zur Entwicklung nachhaltiger Anlageklassen, mittlerweile Managementaufgabe der Unternehmensethik, um Nachfragebedürfnisse zu bedienen. Hierbei werden Umweltaspekte (Emissionen, Verschmutzungen etc.), soziale Aspekte (Arbeitssicherheit, Schutz vor Kinderarbeit etc.) und auch Aspekte der Unternehmensführung (Unternehmenswerte, Qualitätsmanagement etc.) von Anleger:innen, Geschäftspartner:innen oder auch Konsument:innen beurteilt und gewichtet.",
  },
  {
    id: "ESRS",
    name: "ESRS",
    description:
      "European Sustainability Reporting Standards: Diese Leitlinien definieren die Inhalte für eine Nachhaltigkeitsberichterstattung. Es geht dabei um Nachhaltigkeitsthemen im Unternehmen, die sowohl Auswirkungen auf Mensch oder Umwelt (impact materiality) als auch erhebliche finanzielle Auswirkungen haben können (financial materiality).",
  },
  {
    id: "GRI",
    name: "GRI",
    description:
      "Die Global Reporting Initiative veröffentlicht Richtlinien für die Erstellung von Nachhaltigkeitsberichten. Die internationale Initiative setzt Nachhaltigkeitsstandards für die Berichterstattung, die vor allem von global agierenden Großunternehmen genutzt werden.",
  },
  {
    id: "LCA",
    name: "LCA",
    description:
      "Life cycle assessments: Ökobilanzen, siehe unter „Zentrale Begriffe“",
  },
  {
    id: "LkSG",
    name: "LkSG",
    description:
      "Lieferkettensorgfaltspflichtengesetz: siehe unter „Zentrale Begriffe“",
  },
  {
    id: "NAP",
    name: "NAP",
    description:
      "Nationaler Aktionsplan Wirtschaft und Menschenrechte: Der Plan legt Standards fest und verankert so die Verantwortung deutscher Unternehmen entlang von Liefer- und Wertschöpfungsketten. Diese unternehmerische Sorgfaltspflicht erstreckt sich auf die Stärkung der Menschenrechte und eine sozialverträgliche Globalisierung.",
  },
  {
    id: "PAI",
    name: "PAI",
    description: "Principal Adverse Impacts: siehe unter „Zentrale Begriffe“",
  },
  {
    id: "RTS",
    name: "RTS",
    description:
      "Regulatory Technical Standards (Technische Regulierungsstandards): Die Standards legen die Informationen fest, die in der vorvertraglichen und regelmäßigen Dokumentation zu einem Finanzprodukt aufgeführt werden müssen. Ziel ist die Eindämmung von Greenwashing. Hierzu wird nun eine einheitliche Kennzeichnung angestrebt. Diese Kennzeichnung weist allgemein verbindliche Indikatoren, die immer zu den wichtigsten nachteiligen Auswirkungen (siehe PAI – Principal Adverse Impacts) führen, sowie  fakultative Indikatoren zur Feststellung, Bewertung und Gewichtung der Berücksichtigung von zusätzlichen wichtigen nachteiligen Auswirkungen aus.",
  },
  {
    id: "SFDR",
    name: "SFDR",
    description: (
      <span>
        Sustainable Finance Disclosure Regulation: Mit der SFDR regelt die EU
        „die Verpflichtungen zur Offenlegung der Nachhaltigkeit für Hersteller
        von Finanzprodukten und Finanzberater gegenüber den Endanlegern“, siehe
        hierzu Offenlegungs&shy;verordnung.
      </span>
    ),
  },
  {
    id: "TCFD",
    name: "TCFD",
    description:
      "Task Force on Climate-related Financial Disclosures: Die Task Force wurde von einer internationalen Organisation zur Überwachung des globalen Finanzsystems installiert. Die renommiert besetzte Task Force empfiehlt Investoren und Kapitalgesellschaften, dass sie bei ihrer strategischen Planung und ihrem Risikomanagement die Auswirkungen des Klimawandels auf ihre Finanzmittel und deren Beschaffung am Markt berücksichtigen sollen. Das bedeutet einerseits, dass Anleger positiv wie negativ (vermutete) Verhaltensweisen und Geschäftsmodelle bei ihren Entscheidungen bewerten und entsprechend auch Kapitalgeber dies als Chance oder Risiko mit einpreisen sollten (Internalisierung externer Effekte).",
  },
];
