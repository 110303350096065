export const services_german = {
  startingSection: {
    title: "Passgenaue Informations\u00ADlandschaft",
    anleser:
      "Gesellschaft und Auftraggeber stellen an Bauprojekte heute <strong>höchste Sicherheits- und Umweltansprüche</strong>. Vom Wohngebäude bis zum Hotel-Komplex: Der Nachweis darüber, dass die Immobilie sicher und umweltgerecht geplant, errichtet und betrieben wird, muss jederzeit zur Hand sein. So will es die EU-Taxonomie und so wollen es heute auch die Anleger:innen. <br /> <br /> Wir schaffen für Sie eine effiziente und funktionale Informationslandschaft. Sie vernetzt alle Beteiligten und stellt die Daten zu Ihrem Immobilienprojekt <strong>umfassend und transparent</strong> bereit: Mensch – Technik – Prozesse – Rahmenbedingungen. So vermeiden Sie kostspielige Störungen. Ihr Projekt läuft – wirtschaftlich, nachhaltig und rechtssicher.",
    citation:
      "»Verlässliche Daten und Informationen sind heute eine erfolgs\u00ADentscheidende Ressource. Auch und gerade in der komplexen Immobilien\u00ADbranche.«",
  },
  profit: {
    title: "Unternehmens\u00ADberatung, die sich rechnet.",
    "Für Sie – und für die Welt, in der wir uns bewegen.":
      "Für Sie – und für die Welt, in der wir uns bewegen.",
    "Hohe Profitabilität": "Hohe Profitabilität",
    "– durch strukturiertes Informations\u00ADmanagement":
      "– durch strukturiertes Informations\u00ADmanagement",
    Risikominimierung: "Risikominimierung",
    "– wirtschaftlich, rechtlich, ökologisch und sozial":
      "– wirtschaftlich, rechtlich, ökologisch und sozial",
    Entscheidungssicherheit: "Entscheidungssicherheit",
    "– durch eine umfassende und aktuelle Informationslandschaft":
      "– durch eine umfassende und aktuelle Informationslandschaft",
    "ESG-Konformität": "ESG-Konformität",
    "– durch eine transparente Datenlage in jeder Lebenszyklus-Phase":
      "– durch eine transparente Datenlage in jeder Lebenszyklus-Phase",
    Rechtssicherheit: "Rechtssicherheit",
    "– im Sinne der EU-Taxonomie und der Sustainable Finance Disclosure Regulation (SFDR)":
      "– im Sinne der EU-Taxonomie und der Sustainable Finance Disclosure Regulation (SFDR)",
  },

  beratung: {
    citation:
      "»Structure. Work. Process. Wir schaffen eine Informations\u00ADlandschaft, in der alle Projekt-Beteiligten effizient vorankommen: individuell und als Projekt-Team.«",
    Beratung: "Beratung",
    text: "Unser Beratungsprozess beginnt immer mit einer umfassenden und systemischen <strong>Analyse</strong>. Zuerst ermitteln wir Ihren <strong>Informationsbedarf</strong>: Welche Normen und Kennwerte sind für Sie in Ihrem Immobilienportfolio relevant? Welche Daten und Dokumente benötigen Sie und Ihre Stakeholder? Aber auch: Unter welchen spezifischen <strong>Bedingungen</strong> arbeitet Ihre Organisation im Markt? Welche Ressourcen stehen Ihnen bereit? Wie arbeiten Sie und Ihre Teams? Welche Systeme wenden Sie an? <br /> <br /> <strong>Individuell zugeschnittene Informations\u00ADlandschaft</strong> <br /> Auf dieser Basis erarbeiten wir für Sie ein <strong> unternehmens-, portfolio- und projektspezifisch angepasstes Informationsmanagement-System</strong>: vielschichtig und doch einfach zu verstehen. Wir wenden dabei unsere speziell für Immobilienprojekte entwickelten Analyse-Instrumente an: unsere Chameleon Concepts. Das Ergebnis: die passgenaue Informations\u00ADlandschaft und der optimale Informations\u00ADbeschaffungsprozess für Ihr Handeln – 360° und über den gesamten Lebenszyklus hinweg.",
  },
  pm: {
    citation:
      "»Wir verstehen unser Projekt\u00ADmanagement als Informations\u00ADmanagement. Für erfolgreiche und nachhaltige Immobilien\u00ADprojekte.«",
    title: "Projekt\u00ADmanagement",
    text: "Ob Neubau, Umbau oder Betrieb: Ein Bauvorhaben ist ein  <strong>dynamisches Projekt</strong> in einem <strong>dynamischen Umfeld</strong>. Im Laufe seines Lebenszyklus sind eine Vielzahl an Fachunternehmen und Akteur:innen beteiligt. Deshalb <strong>müssen</strong> einzelne Prozessschritte immer wieder  <strong>angepasst werden</strong>.Kooperativ, kollaborativ und konnektiv. Dafür braucht es transparente Arbeitsmethoden und ein <strong>reaktionsfähiges Management-System</strong>. Mit kurzen Sprints statt starrer Projektablaufpläne. <br /> <br /> <strong>Agil und anpassungsfähig</strong> <br /> Wir etablieren deshalb ein <strong>agiles Projekt\u00ADmanagement-System</strong> für Sie. Es basiert auf einer projekt\u00ADspezifischen <strong>Kommunikations\u00ADarchitektur</strong>, die alle Akteur:innen <strong>in kurzer Taktung</strong> miteinander vernetzt. Die Methoden bilden BIM und Scrum, das Fundament bildet der <strong>Austausch auf Augenhöhe</strong>: Als Teammitglieder auf Zeit schaffen wir eine <strong>integrative</strong> und <strong>produktive Kollaborationskultur</strong> für pragmatische Lösungen. Mit <strong>regelmäßigen gemeinsamen Reviews und Updates</strong>. So können wir die Projektplanung schnell und reibungslos an sich verändernde Bedingungen anpassen.",
  },
  im: {
    "Agiles Informations\u00ADmanagement für Zukunftsprojekte":
      "Agiles Informations\u00ADmanagement für Zukunftsprojekte",
    "Dafür sorgen wir": "Dafür sorgen wir:",
    "schneller Informationsfluss": "schneller Informationsfluss",
    "integrative Zusammenarbeit": "integrative Zusammenarbeit",
    "produktives, offenes Kommunikationsumfeld":
      "produktives, offenes Kommunikationsumfeld",
    "kurze und sichere Kommunikationswege":
      "kurze und sichere Kommunikationswege",
    "optimale, flexible Arbeitsabläufe": "optimale, flexible Arbeitsabläufe",
    "Das bringt's": "Das bringt's:",
    "hohe Anpassungsfähigkeit": "hohe Anpassungsfähigkeit",
    "transparente Kosten": "transparente Kosten",
    "präzise Kalkulationen": "präzise Kalkulationen",
    "pragmatische Lösungen": "pragmatische Lösungen",
    "kürzere Projektzeiten": "kürzere Projektzeiten",
  },
  schulung: {
    text: "Mitarbeiter:innen, die die Methoden und Ziele des Projekts kennen und verstehen, arbeiten <strong>effizient und zielorientiert</strong> am gemeinsamen Projekt. Wir konzipieren unsere Schulungen deshalb <strong>speziell für Ihr Team und Ihre Projekte</strong>: Inhalte und Methoden richten wir an der Zusammensetzung der Gruppe und am Wissensstand der einzelnen Teilnehmer:innen aus. So erzielen unsere Schulungen größtmöglichen Profit. Für Ihre Mitarbeiter:innen, für das Projekt und für Ihr Unternehmen.",
    title: "Schulungen",
    teaser:
      "<strong>Integriert oder separat</strong> <br /> Eingebettet in unser Projektmanagement oder als projektunabhängige Einzelschulung: Unsere Schulungen unterstützen Sie in jeder Phase Ihres Projekts.",
  },
};
