import { useMediaQuery } from "@mantine/hooks";
import { forwardRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import workspace from "../../assets/Chameleon_Concepts_Workspace.jpg";
import compassPurple from "../../assets/compassPurple.svg";
import { Section } from "../home/components/Section";
import { TextSection } from "../home/components/TextSection";

export const Projektmanagement = forwardRef<HTMLDivElement>((_, ref) => {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: paralaxref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });

  const { t } = useTranslation();
  return (
    <Section>
      <div className="flex flex-col items-center w-full">
        <div className="flex justify-center w-full p-10 py-32 text-lightPurple md:px-24">
          <div className="text-3xl lg:w-1/2">
            {t("services.pm.citation")}
            <p className="mt-2 text-xl ">Philipp</p>
          </div>
        </div>
        <img
          className=" lg:hidden max-h-[300px] w-full object-cover object-top"
          src={workspace}
          alt="meeting"
        />
        <div
          ref={ref}
          className="relative py-10 w-full lg:min-h-[800px] flex items-center justify-end lg:px-24"
        >
          <div
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            className=" absolute w-[150px]  h-[500px] -top-32 right-14 bg-fullGreen"
          />
          <img
            className="absolute hidden lg:block left-0 top-0 -z-10 max-w-[60%] object-cover h-full"
            src={workspace}
            alt="workspace"
          />
          <div
            ref={paralaxref}
            className="relative lg:w-[1000px] pt-36 sm:pt-20 sm:p-16 p-10 items-end flex flex-col bg-lightGreen"
          >
            <img
              src={compassPurple}
              className="w-[150px] absolute top-3 right-3"
              alt="Map Icon"
            />
            <div>
              <TextSection title={t("services.pm.title")}>
                <p>
                  <Trans i18nKey="services.pm.text"> </Trans>
                </p>
              </TextSection>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
});
