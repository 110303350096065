export const about_german = {
  intro:
    "»Wir von Chameleon Concepts sind mehr als Consultants. Wir sind Supporter und Guides für Ihr Immobilienportfolio: Ihre Vorweggeher:innen.«",
  "Was die Chameleons anders machen": "Was die Chameleons anders machen",
  "Der Chameleon-Spirit": "Der Chameleon-Spirit",
  spirit:
    "Wir sind für Sie und Ihr Investitions- und Immobilienprojekt da – von der Projektentwicklung über das Projektmanagement bis zum Betrieb. Dabei verstehen wir uns als Teil des Teams, als Teil des Ganzen: Wir arbeiten integrativ – bei Ihnen, mit Ihnen und für Sie. Das ist unser Chameleon-Spirit.",
  "systemisch betrachten": "systemisch betrachten",
  "pragmatisch umsetzen": "pragmatisch umsetzen",
  "zukunftsorientiert denken": "zukunftsorientiert denken",
  "nachhaltig entwickeln": "nachhaltig entwickeln",
  "integrativ zusammenarbeiten": "integrativ zusammenarbeiten",
  karriere: {
    Karriere: "Karriere",
    text: "Wir von Chameleon Concepts sind ständig unterwegs – zu erfolgreichen und nachhaltigen Immobilienprojekten. Eine reizvolle Perspektive? Du kannst uns begleiten: Wenn Du unseren Spirit fühlst und Dich als Ingenieur:in oder Wirtschaftswissenschaftler:in für nachhaltige Immobilienprojekte stark machen willst – gib uns einfach ein Zeichen. Wir freuen uns drauf!",
  },
  chameleons: {
    name: "Dr.-Ing. Philipp Güther",
    title: "Gründer, CEO und Chief\u00A0Information\u00A0Consultant",
    text_part1:
      "Philipp Güther bewegt sich seit mehr als 20 Jahren in der Welt der Immobilienwirtschaft. Er kennt die Baubranche sehr genau und aus unterschiedlichen Perspektiven. Nach seinem Studium des Wirtschaftsingenieurwesens (TU Darmstadt; WU Wien) hat er über Innovationsstrategien bei bauausführenden Unternehmen promoviert (Bauhaus-Universität Weimar). Er war als Strategieberater im Banken- und Immobiliensektor im In- und Ausland, als Innovationsberater im deutschen Mittelstand, als wissenschaftlicher Berater des Hauptverbands der Deutschen Bauindustrie e.V. und als Teamleiter bei HOCHTIEF PPP Solutions GmbH aktiv. Er ist gefragter Referent auf Fachsymposien und (Co-)Autor einschlägiger Publikationen. Darüber hinaus hat er eine Ausbildung zum BIM-Fachingenieur absolviert und ist zertifizierter Projektmanager und Scrum-Master.",
    text_part2:
      "Neben 20 Jahren im Bauwesen sind es seine drei Söhne und seine Leidenschaft für sportliche Herausforderungen in der Natur, die ihn – auch als Führungs- und Beratungspersönlichkeit – prägen und bewegen.",
    text_part3:
      "Mit dieser Expertise und Erfahrung führt er die Projekte unserer Kunden zum Erfolg. Dabei kooperiert er – je nach Informationsbedarf und Projektumfang – mit einem eng geknüpften Netzwerk aus erfahrenen und vertrauten Expert:innen.",
    text_part4:
      "Dazu engagiert sich Philipp auch aktiv in der <1>Bundesinitiative Impact Investing</1> e.V., um Bewusstsein für wirkungsorientiertes, ökologisches und soziales Investieren zu schaffen und konkrete Lösungsansätze für den Immobiliensektor zu erarbeiten.",
  },
};
