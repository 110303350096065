import { Group, Stack } from "@mantine/core";
import location from "../../assets/location.svg";
import map from "../../assets/maptools.svg";
import world from "../../assets/world.svg";

import { useMediaQuery } from "@mantine/hooks";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import { Section } from "../home/components/Section";
import { TextSection } from "../home/components/TextSection";

export function Informationswert() {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { t } = useTranslation();

  const { ref: bgref } = useParallax<HTMLDivElement>({ speed: lg ? -10 : 0 });
  const { ref: contentref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });

  return (
    <Section>
      <div className="flex flex-col w-full lg:flex-row bg-darkPurple">
        <div className="relative flex items-center justify-center p-10 lg:w-1/2 lg: sm:p-16 bg-lightGreen">
          <div className="xl:max-w-[400px]">
            <TextSection title={t("tools.iw.title")}>
              <Trans i18nKey="tools.iw.text" />
            </TextSection>
          </div>
          <div
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            ref={bgref}
            className=" absolute w-[300px]  h-[500px] top-[100%] lg:top-10 lg:-right-[85%] xl:-right-[80%]  right-10 bg-fullGreen"
          />
          <div
            ref={contentref}
            className=" h-[100%] items-center justify-center w-[88%] xl:w-[80%] p-10 top-16 hidden lg:flex sm:p-16 -right-[80%] xl:-right-[70%] absolute z-40 bg-[#CDC5DC]"
          >
            <div className="relative xl:max-w-[400px]">
              <SecondaryContent />
            </div>
          </div>
        </div>
        <div className="relative lg:hidden mt-10 flex items-center justify-center p-10  sm:p-16 bg-[#CDC5DC]">
          <SecondaryContent />
        </div>
      </div>
    </Section>
  );
}

function SecondaryContent() {
  const { t } = useTranslation();
  return (
    <Stack spacing="xl" className="text-lg text-darkPurple">
      <p>
        <Trans i18nKey="tools.iw.sc.title" />
      </p>
      <Group className="items-start flex-nowrap">
        <img className="w-1/5 max-w-[70px]" alt="map" src={map} />

        <p className="w-4/5">
          <b>{t("tools.iw.sc.1. Die Chameleon-Map")}</b>
          <br />
          {t(
            "tools.iw.sc.präsentiert die Informationen – wie eine Landkarte – themenspezifisch zugeordnet und in Beziehung zueinander."
          )}
        </p>
      </Group>
      <Group className="items-start flex-nowrap">
        <img className="w-1/5 max-w-[70px]" alt="world" src={world} />
        <p className="w-4/5">
          <b>{t("tools.iw.sc.2. Der Chameleon-Atlas")}</b>
          <br />
          {t(
            "tools.iw.sc.bündelt alle erfassten Maps zu Ihrem Informations- und Wertschöpfungsraum."
          )}
        </p>
      </Group>
      <Group className="items-start flex-nowrap">
        <img className="w-1/5 max-w-[70px]" alt="location" src={location} />
        <p className="w-4/5">
          <b>{t("tools.iw.sc.3. Das Chameleon-Navi")}</b>
          <br />
          {t(
            "tools.iw.sc.führt Sie zu Ihrem Informations- und Wertschöpfungsziel. Es bietet Ihnen verschiedene „Routen“, um die jeweils erforderlichen Informationen zu gewinnen, zu kombinieren und weiterzuverarbeiten."
          )}
        </p>
      </Group>
    </Stack>
  );
}
