import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LanguageSwitcher } from "../../global/components/LanguageSwitcher";
import { PageTemplate } from "../../global/components/PageTemplate";
import { ToTop } from "../../global/components/ToTop";
import { sleep } from "../../util";
import { Footer } from "../home/components/Footer";
import { ContactButton } from "../home/components/startingSection/ContactButton";
import { Informationsmanagement } from "./Informationsmanagement";
import { Projektmanagement } from "./Projektmanagment";
import { Schulung } from "./Schulung";
import { Beratung } from "./beratung/Beratung";
import { Profit } from "./profit/Profit";
import { StartingSection } from "./startingSection/StartingSection";

export function Services() {
  const beratung = useRef<HTMLDivElement>(null);
  const projektmanagement = useRef<HTMLDivElement>(null);
  const schulung = useRef<HTMLDivElement>(null);

  const location = useLocation();

  useEffect(() => {
    async function scroll() {
      window.scrollTo(0, 0);
      await sleep(300);
      if (location.state === "beratung") {
        beratung.current?.scrollIntoView({ behavior: "smooth" });
      } else if (location.state === "projektmanagement") {
        projektmanagement.current?.scrollIntoView({ behavior: "smooth" });
      } else if (location.state === "schulung") {
        schulung.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    scroll();
  }, [location.state]);

  return (
    <>
      <PageTemplate>
        <StartingSection />
        <Profit />
        <Beratung ref={beratung} />
        <Projektmanagement ref={projektmanagement} />
        <Informationsmanagement />
        <Schulung ref={schulung} />
        <ContactButton startingY={50} />
        <LanguageSwitcher />
        <ToTop />

        <Footer />
      </PageTemplate>
    </>
  );
}
