export const home_german = {
  Kontakt: "Kontakt",
  slogan:
    "Integrative Unternehmens\u00ADberatung für ein Immobilien\u00ADmanagement, das beides ist: erfolgreich und nachhaltig.",
  service: {
    "der Guide für Ihr Immobilien-Portfolio.":
      "der Guide für Ihr Immobilien-Portfolio.",
    "Für Investoren, Kapitalgeber, Konzerne und öffentliche Institutionen.":
      "Für Investoren, Kapitalgeber, Konzerne und öffentliche Institutionen.",

    "Sie agieren\nESG-konform.": "Sie agieren\nESG-konform.",
    "Sie beherrschen\ndie Risiken.": "Sie beherrschen\ndie Risiken.",
    "Sie maximieren\nIhre Profitabilität.":
      "Sie maximieren\nIhre Profitabilität.",

    "Sie agieren ESG-konform.": "Sie agieren ESG-konform.",
    "Sie beherrschen die Risiken.": "Sie beherrschen die Risiken.",
    "Sie maximieren Ihre Profitabilität.":
      "Sie maximieren Ihre Profitabilität.",
    Beratung: "Beratung",
    beratung_text:
      "Wir analysieren Ihren Informations\u00ADbedarf für Ihr Immobilienprojekt. Wir definieren die optimale Informations\u00ADlandschaft für Sie. Und schaffen ein leistungsstarkes und effizientes Informations\u00ADmanagement-System, das exakt auf Ihr Projekt und Ihre Organisation zugeschnitten ist.",
    Projektmanagement: "Projekt\u00ADmanagement",
    projektmanagement_text:
      "Kurze Sprints und regelmäßige Reviews: Wir arbeiten eng mit Ihnen und Ihren Teams zusammen – mit einem agilen Projekt\u00ADmanagement-System. So lassen sich Prozessabläufe schnell und einfach anpassen, wenn sich etwas im oder um Ihr individuelles Immobilienprojekt ändert.",
    Schulung: "Schulung",
    schulung_text:
      "Wir schulen Ihre Mitarbeiter:innen mit erprobten und integrativen Methoden. Inhouse und in kleinen Gruppen. Damit Sie und Ihre Teams jederzeit wissen, worauf es ankommt.",
  },

  tools: {
    "Strategie-Konzepte und Management-Instrumente.":
      "Strategie-Konzepte und Management-Instrumente.",
    "Wissenschaftlich entwickelt,\npragmatisch eingesetzt.":
      "Wissenschaftlich entwickelt,\npragmatisch eingesetzt.",
    cc: "Speziell entwickelte Instrumente für Ihre individuelle Informations\u00ADlandschaft",
    bim: "Die Organisationsmethode für effizientes und nachhaltiges Informationsmanagement",
    scrum: "In cleveren Sprints zum gemeinsamen Projektziel",
  },
  insights: {
    "Have-to-knows,\nGood-to-knows und\nNice-to-knows.":
      "Have-to-knows,\nGood-to-knows und\nNice-to-knows.",
    "Rund um ESG-konforme Immobilienprojekte.":
      "Rund um ESG-konforme Immobilienprojekte.",

    "Have-to-knows, Good-to-knows und Nice-to-knows.":
      "Have-to-knows, Good-to-knows und Nice-to-knows.",
    Glossar: "Glossar",
    glossar:
      "A wie Active Stewardship, B wie Betreiberverantwortung, C wie CO\u2082-Äquivalente – wir bringen alle relevanten Begriffe auf den Punkt. Bis Z wie Zertifizierungssysteme.",
  },
  footer: {
    Impressum: "Impressum",
    Datenschutz: "Datenschutz",
  },
};
