import { ReactNode } from "react";

export function Section({ children }: { children: ReactNode }) {
  return (
    <div
      style={{ minHeight: "calc(max(100vh, 800px))" }}
      className="relative flex justify-center"
    >
      {children}
    </div>
  );
}
