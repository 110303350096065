import logo from "../../../../assets/logo.svg";

export function Logo() {
  return (
    <img
      className="absolute z-10 top-32 left-5 md:left-16 lg:left-32"
      alt="logo"
      src={logo}
    />
  );
}
