import { LanguageSwitcher } from "../../global/components/LanguageSwitcher";
import { PageTemplate } from "../../global/components/PageTemplate";
import { ToTop } from "../../global/components/ToTop";
import { Footer } from "./components/Footer";
import { InsightsSection } from "./components/insights/InsightsSection";
import { ServiceSection } from "./components/serviceSection/ServiceSection";
import { ContactButton } from "./components/startingSection/ContactButton";
import { StartingSection } from "./components/startingSection/StartingSection";
import { ToolsAndSystemsSection } from "./components/toolsAndSystemsSection/ToolsAndSystemsSection";

export function Home() {
  return (
    <>
      <PageTemplate>
        <StartingSection />
        <ServiceSection />
        <ToolsAndSystemsSection />
        <InsightsSection />
        <Footer />
        <ContactButton />
        <LanguageSwitcher />
        <ToTop />
      </PageTemplate>
    </>
  );
}
