import { Group } from "@mantine/core";
import { ReactNode } from "react";
export const begriffe_english: {
  id: string;
  name: ReactNode;
  description: ReactNode;
}[] = [
  {
    id: "Active Stewardship",
    name: "Active Stewardship",
    description:
      "A management approach that requires asset managers or even the investor himself to invest capital responsibly, namely consciously, in sustainable financial products. These investors act proactively in their investment decisions in the capital market and solve an apparent contradiction: the objectives of an ESG-compliant and financially attractive, value-enhancing Portfolio are compatible.",
  },
  {
    id: "Certification ",
    name: "Certification ",
    description:
      "Property owners have been offered certification and evaluation systems about sustainability criteria in the national and international real estate markets for several years. In particular, the DGNB, BREEAM, and LEED certificates are common here. Thus, these can be a good guide for the information goals of the stakeholders of a property to meet the objectives of the EU taxonomy.",
  },
  {
    id: "Climate neutrality",
    name: "Climate neutrality",
    description: (
      <>
        "Climate-neutral" strictly refers to activities that do not affect the
        climate. However, the term is often used vaguely. It is correct to
        distinguish between:
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            "CO
            <sub>2</sub>-neutral" for activities that do not have CO<sub>2</sub>
            -Cause emissions
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            "CO
            <sub>2</sub>e-neutral", "greenhouse gas neutral", "net zero
            emissions", or "zero greenhouse gas emissions" for activities that
            do not cause greenhouse gas emissions
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          "climate neutral" for activities that do not affect the climate.
        </Group>
        Various sustainability measures are behind the label "climate neutral"
        of products or organizations. They range from compensation payments,
        e.g., in the form of CO<sub>2</sub> certificates, to implementing
        technical and biological measures to reduce the company's emissions or
        absorb climate-damaging gases.
      </>
    ),
  },
  {
    id: "CO2-Equivalent",
    name: (
      <span>
        CO<sub>2</sub>-Equivalent
      </span>
    ),
    description: (
      <span>
        CO<sub>2</sub>-Equivalents (CO<sub>2</sub>(e) is the unit of measurement
        used to standardize the climate impact of different greenhouse gases,
        which includes CO<sub>2</sub>, and methane. It determines the global
        warming potential of substances and considers the time dimension.
      </span>
    ),
  },
  {
    id: "CSR Directive Implementation Act",
    name: "CSR Directive Implementation Act",
    description:
      "(CSR-DIA) explains the reporting obligation on non-financial aspects, such as environmental, employee, and social matters, respect for human rights, and the fight against corruption and bribery. These aspects must be explained within the business model. The business result, and the strategic concepts, material risks and performance indicators must also be presented.",
  },
  {
    id: "Digital Twin",
    name: "Digital Twin",
    description:
      "A visualized building model as an image of a physically real building becomes a dynamic and updated information and calculation model. Digital data from the technical building equipment is constantly transmitted, and a wide variety of visual and numerical simulations are carried out in the twin to predict current trends and types of building use for future use.",
  },
  {
    id: "Disclosure Regulation",
    name: "Disclosure Regulation",
    description:
      "With Regulation 2019/2088, the European Union has established a framework for transparent reporting on sustainability risks in the capital market. The regulation objective is to disclose information to financial market participants on the inclusion of sustainability risks, on the consideration of adverse sustainability impacts and sustainable investment objectives, or on the promotion of environmental or social characteristics in investment decisions and in the advisory process. Furthermore the regulation should be facilitate investments into measures or assets that are sustainable or represent sustainability-related solutions. The so-called Article 8 and Article 9 products are defined in the regulation.",
  },
  {
    id: "Embodied carbon",
    name: "Embodied carbon",
    description:
      "Embodied carbon aka. grey emissions are those climate-damaging emissions caused by the production, transport, and disposal of building materials and so they a part of the life cycle assessment of real estate. In other words, emissions are not only emitted by operation, they are also contained indirectly in numerous construction materials.",
  },
  {
    id: "EU-Taxonomy",
    name: "EU-Taxonomy",
    description: (
      <span>
        The taxonomy (EU Regulation 2020/852) is a uniform classification system
        to provide transparent and comparable information on sustainable
        economic activities in investments, portfolios, and business areas. The
        taxonomy categorizes the economic activities of major industries
        responsible for large CO<sub>2</sub> emissions and defines thresholds to
        classify them as environmentally sustainable. This is followed by an
        assessment of whether economic activities are environmentally
        sustainable under the following four conditions: <br />
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            Substantial contribution to the achievement of one of the EU's
            environmental objectives;
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            Prevention of significant infringement of other EU environmental
            objectives;
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            Compliance with minimum requirements in the areas of governance,
            human and labor rights;
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            Fulfillment of the specified technical screening criteria.
          </span>
        </Group>
      </span>
    ),
  },
  {
    id: "Life Cycle Assessment",
    name: "Life Cycle Assessment",
    description:
      "Life Cycle Assessment is a procedure for recording and evaluating environmentally relevant processes. Life Cycle Assessments (LCAs) look at cross-media (e.g., harmful effects in soil, air, water) and material flow integrated (e.g., consumption and emissions in supply and disposal processes). Life Cycle Assessment usually includes: 1. definition of objective and scope of investigation, 2. life cycle inventory, 3. impact assessment, 4. evaluation.",
  },
  {
    id: "Materiality analysis",
    name: "Materiality analysis",
    description:
      "The materiality analysis deals with the effects of a company's activities on its environment, but at the same time, also considers the effects of the environment on the company. The direct and indirect environmental and social influences regarding sustainability are analyzed according to their positive and negative effects, and their findings are transferred to the company's processes.",
  },

  {
    id: "Operator's responsibility",
    name: "Operator's responsibility",
    description: `In addition to owners, property operators may also include companies and individual employees who operate a building or a technical building system. This is because operator obligations can be transferred. Operator responsibility deals with "operator obligations" and "entrepreneur obligations". These specifications formulate tasks to be understood as the operator's responsibility for a property. On one hand, there must be no danger from the property and equipment (traffic safety obligation). On the other hand, there must be other legal requirements, e.g., at workplaces and guaranteed by the property operator. Likewise, not only harm to people through responsible action must be averted, but also to property. Thus, emissions are also subject to the operator's responsibility, not to damage the real-estate environment.`,
  },
  {
    id: "Principal Adverse Impacts",
    name: "Principal Adverse Impacts",
    description:
      "Principal Adverse Impacts (PAI) must cover all three dimensions of the ESG management approach. Investors must analyze, name, and disclose these sustainability-damaging influences in their investment decisions. In addition, explanations must be given, prioritization must be reported as to how the influences are to be reduced in a quantitatively measurable way and what measures are to be taken to achieve the target values. However, there are also certain minimum exclusions and requirements for the investment decision.",
  },
  {
    id: "Scope",
    name: "Scope",
    description: (
      <span>
        In the sustainability debate, greenhouse gas emissions are divided into
        three scopes.
        <br />
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          Scope I cover all emissions directly caused, controlled by the
          company, and thus are responsible for company's service creation
          processes or business activities.
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          Scope II covers certain emissions that a company can control and
          account for indirectly or conditionally. Here, energy consumption for
          the operation of the operating objects is subsumed. The emissions are,
          therefore, not caused by the company itself.
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          Finally, Scope III covers other indirect emissions that cannot be
          caused or controlled by the company, but are indirectly responsible
          for them. These are emissions caused by the use of the goods and
          services created and offered by the company or also caused by
          suppliers in the upstream stages of the value chain outside the
          company.
        </Group>
      </span>
    ),
  },
  {
    id: "Supply Chain Due Diligence Act",
    name: "Supply Chain Due Diligence Act",
    description:
      "The Supply Chain Due Diligence Act, or Supply Chain Act, which came into force in Germany on January 01, 2023, regulates corporate responsibility in Germany for compliance with human rights in global supply chains. This means that companies are responsible for the risks of violations along their entire supply chain. Thus, companies should identify, assess, and prioritize these risks in their supply chains. Based on the results, a policy statement must then be published including the measures taken to avoid or minimize violations of human rights and damage to the environment. The law sets out which preventive and remedial measures are necessary.",
  },
  {
    id: "Use Case",
    name: "Use Case",
    description:
      "The Use Case determines what the BIM working methodology is needed for. Explained: What do I want to achieve with it, or which business activity should be implemented in digitally structured processes in the building model?",
  },
];
