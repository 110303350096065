import { NavBar } from "../../global/components/NavBar";

export function NavbarSection() {
  return (
    <div className="flex justify-center ">
      <div className="flex flex-col bg-[#F0EDF4] w-full">
        <NavBar current="about" />
      </div>
    </div>
  );
}
