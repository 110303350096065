import { useHover, useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import workspace from "../../../../assets/Chameleon_Concepts_Insights.jpg";
import { TextSection } from "../TextSection";
import { Link } from "../startingSection/Link";

export function InsightsSection() {
  const { hovered: mobilehovered, ref: mobilehoverref } = useHover();
  const { hovered, ref } = useHover();
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: parallaxref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex-col items-start justify-between hidden mb-8 space-y-8 lg:flex">
        <div className="ml-32 ">
          <Link href="/insights" variant="purple">
            Insights
          </Link>
        </div>
        <p className="px-10 pl-32 text-2xl whitespace-pre-wrap sm:text-3xl lg:text-4xl text-darkPurple">
          {t("home.insights.Have-to-knows,\nGood-to-knows und\nNice-to-knows.")}
          <p className="mt-1 text-lg text-darkPurple">
            {t("home.insights.Rund um ESG-konforme Immobilienprojekte.")}
          </p>
        </p>
      </div>

      <div
        style={{ minHeight: "calc(max(80vh, 600px))" }}
        className="relative flex justify-center"
      >
        <div className="flex-row items-end hidden lg:flex">
          <div className="relative flex flex-col items-end justify-end w-full h-full space-y-8">
            <img
              src={workspace}
              alt="workspace"
              className="max-h-[600px] w-screen"
              style={{ objectFit: "cover", objectPosition: "left" }}
            />
            <div
              className="h-[115%] cursor-pointer  absolute flex items-center md:p-16 right-[5%] -top-10 z-30  p-10 bg-[#F0EDF4] w-[380px]"
              ref={parallaxref}
            >
              <div
                ref={ref}
                onClick={() => {
                  navigate("/insights");
                }}
                className="flex items-center w-full h-full cursor-pointer"
              >
                <Glossar hovered={hovered} />
              </div>
            </div>
          </div>
        </div>
        {/* mobile */}
        <div className="flex flex-col max-w-[100vw] lg:hidden">
          <div className="flex flex-col justify-between h-full p-10 space-y-8 md:p-16">
            <Link href="/insights" variant="purple">
              Insights
            </Link>
            <p className="text-2xl sm:text-3xl lg:text-4xl text-darkPurple">
              {t(
                "home.insights.Have-to-knows, Good-to-knows und Nice-to-knows."
              )}
              <p className="mt-4 text-lg font-bold text-lightPurple">
                {t("home.insights.Rund um ESG-konforme Immobilienprojekte.")}
              </p>
            </p>
          </div>
          <img
            src={workspace}
            alt="workspace"
            className="w-full h-[300px]"
            style={{ objectFit: "cover", objectPosition: "left" }}
          />
          <div
            ref={mobilehoverref}
            onClick={() => {
              navigate("/insights");
            }}
            className="flex flex-col cursor-pointer  justify-between space-y-20 md:p-16  p-10 bg-[#F0EDF4]"
          >
            <Glossar hovered={mobilehovered} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Glossar({ hovered }: { hovered: boolean }) {
  const { t } = useTranslation();
  return (
    <TextSection hovered={hovered} title={t("home.insights.Glossar")}>
      {t("home.insights.glossar")}
    </TextSection>
  );
}
