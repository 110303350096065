import { useMediaQuery } from "@mantine/hooks";
import { forwardRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import workspace from "../../assets/schulung.svg";
import shoePurple from "../../assets/shoePurple.svg";
import { TextSection } from "../home/components/TextSection";

export const Schulung = forwardRef<HTMLDivElement>((_, ref) => {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: hinweisref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });
  const { ref: bgref } = useParallax<HTMLDivElement>({
    speed: lg ? -10 : 0,
  });

  const { t } = useTranslation();
  return (
    <div
      style={{ minHeight: "calc(max(70vh, 600px))" }}
      className="relative flex justify-center"
    >
      <div className="w-full flex  justify-center bg-[#F0EDF4]">
        <div className="relative flex flex-col items-center justify-center w-full lg:mb-24 lg:flex-row max-w-7xl lg:p-10">
          <div
            ref={bgref}
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            className=" absolute lg:w-[20%] w-[40%] h-[400px]  -top-[50px] right-[48%] bg-fullGreen"
          />

          <div
            ref={ref}
            className="relative  lg:w-[500px] h-fit pt-32 sm:pt-20 sm:p-16 p-10 items-end flex flex-col lg:-translate-y-24 bg-[#9D89B9]"
          >
            <img
              src={shoePurple}
              className="w-[150px] absolute top-3 right-3"
              alt="Map Icon"
            />
            <div
              ref={hinweisref}
              className="py-10 px-8 z-20 w-[70%] -bottom-[220px] text-lightPurple absolute hidden lg:block font-sans -right-32 text-lg bg-darkPurple"
            >
              <SchulungText />
            </div>
            <TextSection title={t("services.schulung.title")}>
              <Trans i18nKey={"services.schulung.text"} />
            </TextSection>
          </div>
          <div className="p-10 font-sans text-lg sm:p-16 lg:mt-3 text-lightPurple lg:hidden bg-darkPurple">
            <SchulungText />
          </div>
          <img
            src={workspace}
            className="lg:max-w-[50%] max-w-[min(100%,500px)]"
            alt="workspace scg"
          />
        </div>
      </div>
    </div>
  );
});

export function SchulungText() {
  return <Trans i18nKey={"services.schulung.teaser"} />;
}
