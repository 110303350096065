export const tools_english = {
  startingSection:
    "The real estate sector is highly complex. Profound strategy concepts and special management tools are must-haves to build and operate your real estate's long-term profitability.<br /> <br />Our tools and methods were developed scientifically. They were often implemented and they will deploy pragmatically.",
  iw: {
    title: "Chameleon Concepts – Information value added for your asset.",
    text: "Using our Chameleon Concepts – that we developed especially for the real estate sector – we create an <strong>information-based value-added system</strong>. Though your business model may be complete and precise, this system maps every real estate and environment.<br /> <br />    It reports every <strong>technical and economic necessary information and figures</strong> and, in addition, all relevant data and documents for the <strong>ESG topic along the whole project life</strong>. Above and beyond this information, you'll be able to get a forecast of your strategic development potentialities.",
    sc: {
      title: "We map your value-added system in three dimensions:",
      "1. Die Chameleon-Map": "1. Chameleon-Map",
      "präsentiert die Informationen – wie eine Landkarte – themenspezifisch zugeordnet und in Beziehung zueinander.":
        "We are presenting all information like a map – issue-specific and about other factors.",
      "2. Der Chameleon-Atlas": "2. Chameleon-Atlas",
      "bündelt alle erfassten Maps zu Ihrem Informations- und Wertschöpfungsraum.":
        "Bundling all acquired maps about your information scope and value-added scope.",
      "3. Das Chameleon-Navi": "3. Chameleon-Navi",
      "führt Sie zu Ihrem Informations- und Wertschöpfungsziel. Es bietet Ihnen verschiedene „Routen“, um die jeweils erforderlichen Informationen zu gewinnen, zu kombinieren und weiterzuverarbeiten.":
        "We are guiding you to your information and value-added target. This navigation system offers alternative routes to winning, combining, and processing necessary information.",
    },
  },
  citation:
    "»Chameleon Concepts is your tour guide in your real estate landscape. We know the shortest ways, the safest bridges, and the fastest shortcuts. We have everything in our backpack that will be helpful on your tour well-assorted and always close to hand.«",

  bim: {
    title: "BIM – always up to date",
    text: "We create your value-added scope together with you. For this, we implement a modern and efficient method to introduce sustainable information management in your business processes: <strong>Building Information Modeling – BIM</strong>.<br /> <br />This method uses a 3D-building model to structure every piece of information in context to others. Furthermore, all <strong>project members</strong> get <strong>crosslinked</strong>. So, they can provide relevant data, look into each other's provided data, and process these data for their interests. These characteristics of the method take care of an <strong>actual dataset</strong> and allow a <strong>fluent</strong> and <strong>reliable procurement process</strong> for information over the entire lifecycle.",

    "Immer auf dem Stand aktuelle Informationen mit einem Klick":
      "Always up to date: actual information with a click!",
    "Umfassendes Informations\u00ADsystem –": "Comprising information system –",
    "für das komplette Bauwerk und seinen gesamten Lebenszyklus":
      "for the full structure",
    "Einfache Informations\u00ADbeschaffung –":
      "Easy procurement of information –",
    "für alle Akteurinnen": "for all stakeholders",
    "Durchgängiger Informations\u00ADfluss –": "Consistent information flow –",
    "dank individuell aufgesetztem Informations\u00ADbeschaffungsprozess und optimal angepassten Informationsschnittstellen":
      "due to individual-created information procurement processes and optimally adjusted interfaces of information",
    "Transparente Nachhaltigkeits\u00ADdaten –":
      "Transparent data of sustainability –",
    "für zukunftssichere Managemententscheidungen":
      "for future-proof management decisions",
    "Effiziente Nachhaltigkeits\u00ADbericht\u00ADerstattung –":
      "Efficient sustainability reporting –",
    "dank Building Information Modeling (BIM)":
      "due to Building Information Modeling (BIM)",
  },
  scrum: {
    title: "Scrum: effective collaboration culture",

    text: "By using Scrum, we implement a contemporary, <strong>agile project management methodology</strong>. You set the target of your project. The way to it is arranged by your staff <strong>on one's initiative and independently</strong>: Your experts will develop one's potential at its best by self-organized realization. If they are confronted with challenges not considered, they will find solutions through collaboration that they would implement. They meet one another in short intervals to dovetail their interim findings and manage the next steps. Scrum allows them to react fast and <strong>cooperatively</strong> to changes and bring them in <strong>short sprints to the common target</strong>: profitable construction and operation of your real estate.",
  },
};
