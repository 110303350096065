import logo from "../../assets/footerlogo.svg";
import { Link } from "../../pages/home/components/startingSection/Link";

export function NavBar({ current }: { current: string }) {
  return (
    <div className="flex w-full">
      <div className="z-20 flex flex-col-reverse w-full px-[45px] sm:pl-16 pt-10 pb-10 lg:justify-between h-fit lg:w-2/3 lg:space-x-14 lg:flex-row bg-darkPurple">
        <a href="/">
          <img src={logo} className="w-[330px] lg:mt-0 mt-10 " alt="logo" />
        </a>

        <div className="flex flex-row items-center space-x-6 lg:justify-end ">
          <Link
            href="/services"
            variant={current === "services" ? "fullgreen" : "purple"}
          >
            Services & profit
          </Link>
          <Link
            href="/tools"
            variant={current === "tools" ? "fullgreen" : "purple"}
          >
            Tools & systems
          </Link>
          <Link
            href="/about"
            variant={current === "about" ? "fullgreen" : "purple"}
            onliner
          >
            About
          </Link>
          <Link
            href="/insights"
            variant={current === "insights" ? "fullgreen" : "purple"}
            onliner
          >
            Insights
          </Link>
        </div>
      </div>
    </div>
  );
}
