export const home_english = {
  Kontakt: "Contact",
  slogan:
    "Integrative Real Estate Consultancy that is both successful and sustainable.",
  service: {
    "der Guide für Ihr Immobilien-Portfolio.":
      "the Guide for your Real Estate Asset.",
    "Für Investoren, Kapitalgeber, Konzerne und öffentliche Institutionen.":
      "Addressed to Investors, Financiers, Corporations, and Public Institutions.",

    "Sie agieren\nESG-konform.": "You are going to\nact compliantly to ESG.",
    "Sie beherrschen\ndie Risiken.": "You are going to\nmanage risks.",
    "Sie maximieren\nIhre Profitabilität.":
      "You are going to\nmaximize your\nprofitability.",

    "Sie agieren ESG-konform.": "You are going to act compliantly to ESG.",
    "Sie beherrschen die Risiken.": "You are going to manage risks.",
    "Sie maximieren Ihre Profitabilität.":
      "You are going to maximize your profitability.",
    Beratung: "Consulting",
    beratung_text:
      "We analyze your personal information needs according to the real estate project. We define your optimized information landscape. We build a high-performing and efficient Information Management System totally customized precisely tailored to your project and your organization.",
    Projektmanagement: "Project Management",
    projektmanagement_text:
      "Short sprints and steady reviews: We work closely with you and your teams – using an agile Project Management System. Processes can be adjusted quickly and easily if any change occurs inside or outside of your real estate project.",
    Schulung: "Training",
    schulung_text:
      "We train your staff by using proven and integrative methods via In-house or in small groups, so that you and your staff know the project values and essential factors that they need to give importance to.",
  },

  tools: {
    "Strategie-Konzepte und Management-Instrumente.":
      "Strategy concepts and management tools",
    "Wissenschaftlich entwickelt,\npragmatisch eingesetzt.":
      "Developed scientifically,\ndeployed pragmatically!",
    cc: "These are tools that are developed especially for your information landscape.",
    bim: "Innovative management method for efficient and sustainable information management.",
    scrum: "Get your project target in smart sprints.",
  },
  insights: {
    "Have-to-knows,\nGood-to-knows und\nNice-to-knows.":
      "Have to know,\ngood to know,\nnice to know.",
    "Rund um ESG-konforme Immobilienprojekte.":
      "Everything about ESG-compliant real estate projects.",

    "Have-to-knows, Good-to-knows und Nice-to-knows.":
      "Have to know, good to know, nice to know.",
    Glossar: "Glossary",
    glossar:
      "A as Active Stewardship, B as BIM, C as CO\u2082-Equivalent – most relevant terms straight to the point.",
  },

  footer: {
    Impressum: "Imprint",
    Datenschutz: "Privacy policy",
  },
};
