import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../global/components/LanguageSwitcher";
import { NavBar } from "../../global/components/NavBar";
import { PageTemplate } from "../../global/components/PageTemplate";
import { ToTop } from "../../global/components/ToTop";
import { Footer } from "../home/components/Footer";
import { Section } from "../home/components/Section";
import { TextSection } from "../home/components/TextSection";
import { ContactButton } from "../home/components/startingSection/ContactButton";

export function Impressum() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Section>
        <div
          style={{
            background:
              "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
          }}
          className="absolute w-[45%] h-[150px] top-56 lg:top-28 lg:left-1/4 right-5   z-10 bg-fullGreen"
        />
        <div className="flex flex-col w-full h-full bg-darkPurple">
          <NavBar current="" />
          <div className="px-10 my-24 lg:pt-14 sm:px-16">
            <TextSection titleColor="white" title={t("imprint.Impressum")}>
              <div className="text-white">
                <Trans i18nKey="imprint.p1" />
                <a
                  className="cursor-pointer"
                  target="_blank"
                  rel="noreferrer"
                  href="https://merletebbe.de/"
                >
                  Merle Tebbe
                </a>
                <br />
                {t("imprint.Programmierung")}{" "}
                <a
                  className="cursor-pointer"
                  href="mailto:ferdinand.baumgarten@yahoo.com"
                >
                  Ferdinand Baumgarten
                </a>
                <Trans i18nKey="imprint.p2" />
              </div>
            </TextSection>
          </div>
        </div>
      </Section>
      <ContactButton startingY={50} />
      <LanguageSwitcher />
      <Footer current="impressum" />
      <ToTop />
    </PageTemplate>
  );
}
