import i18next from "i18next";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { abkuerzungen_german } from "../../data/abkuerzungen";
import { abkuerzungen_english } from "../../data/abkuerzungen_english";
import { begriffe_german } from "../../data/zentraleBegriffe";
import { begriffe_english } from "../../data/zentraleBegriffe_english";
import { LanguageSwitcher } from "../../global/components/LanguageSwitcher";
import { NavBar } from "../../global/components/NavBar";
import { PageTemplate } from "../../global/components/PageTemplate";
import { ToTop } from "../../global/components/ToTop";
import { Footer } from "../home/components/Footer";
import { Section } from "../home/components/Section";
import { TextSection } from "../home/components/TextSection";
import { ContactButton } from "../home/components/startingSection/ContactButton";

// all letters of the alphabet which are starting letter of either begriffe or abkuerzungen

export function Insights() {
  const [current, setCurrent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCurrent("");
  }, [current]);

  const { t } = useTranslation();

  const [begriffe, setBegriffe] = useState(
    i18next.language === "en" ? begriffe_english : begriffe_german
  );
  const [abkuerzungen, setAbkuerzungen] = useState(
    i18next.language === "en" ? abkuerzungen_english : abkuerzungen_german
  );

  i18next.on("languageChanged", (lng) => {
    setBegriffe(lng === "en" ? begriffe_english : begriffe_german);
    setAbkuerzungen(lng === "en" ? abkuerzungen_english : abkuerzungen_german);
  });

  const alphabet = "abcdefghijklmnopqrstuvwxyz".split("").filter((letter) => {
    return (
      begriffe.filter((begriff) => begriff.id[0].toLowerCase() === letter)
        .length > 0 ||
      abkuerzungen.filter((abk) => abk.id[0].toLowerCase() === letter).length >
        0
    );
  });

  return (
    <PageTemplate>
      <div className="flex justify-center mb-0 ">
        <div className="flex flex-col w-full">
          <NavBar current="insights" />
        </div>
      </div>
      <Section>
        <div className="flex flex-col">
          <div className="flex flex-col w-full lg:flex-row">
            <div className="w-full p-10 lg:w-1/2 sm:p-16 bg-glossarBG lg:pt-40 ">
              <TextSection title={t("insights.Zentrale Begriffe")}>
                <p className="text-xl text-white ">
                  {begriffe.map((begriff) => (
                    <>
                      <span
                        className="cursor-pointer hover:text-darkPurple"
                        onClick={() => setCurrent(begriff.id)}
                        key={`link-${begriff.id}`}
                      >
                        {begriff.name}
                      </span>
                      <span
                        key={`seperator-${begriff.id}`}
                        className="last:hidden"
                      >
                        {" "}
                        |{" "}
                      </span>
                    </>
                  ))}
                </p>
              </TextSection>
            </div>
            <div className="w-full p-10 bg-white lg:pt-40 lg:w-1/2 sm:p-16">
              <TextSection title={t("insights.Häufige Abkürzungen")}>
                <p className="text-xl text-lightPurple">
                  {abkuerzungen.map((abk) => (
                    <>
                      <span
                        className="cursor-pointer hover:text-darkPurple"
                        onClick={() => setCurrent(abk.id)}
                        key={`link-${abk.id}`}
                      >
                        {abk.name}
                      </span>
                      <span key={`seperator-${abk.id}`} className="last:hidden">
                        {" "}
                        |{" "}
                      </span>
                    </>
                  ))}
                </p>
              </TextSection>
            </div>
          </div>
          {alphabet.map((letter, i) => (
            <div
              className={`flex ${
                begriffe.filter(
                  (begriff) => begriff.id[0].toLowerCase() === letter
                ).length === 0 &&
                abkuerzungen.filter((abk) => abk.id[0].toLowerCase() === letter)
                  .length === 0 &&
                "hidden"
              } flex-col`}
              key={`letter-${letter}`}
            >
              <div className="flex w-full ">
                <div
                  className={`w-full pt-10 sm:pt-16 lg:pt-0 px-10 lg:w-1/2 sm:px-16 lg:pr-0 lg:bg-glossarBG ${
                    begriffe.filter(
                      (begriff) => begriff.id[0].toLowerCase() === letter
                    ).length > 0
                      ? "bg-glossarBG"
                      : "bg-white"
                  }`}
                >
                  <div className="w-full font-serif text-4xl border-b-2 text-darkPurple border-darkPurple">
                    {letter.toUpperCase()}
                  </div>
                </div>
                <div className="hidden px-10 pl-0 bg-white sm:px-16 sm:pl-0 lg:flex lg:w-1/2">
                  <div className="w-full border-b-2 border-darkPurple"></div>
                </div>
              </div>
              <div className="flex flex-col w-full lg:flex-row ">
                <div
                  className={`w-full ${
                    begriffe.filter(
                      (begriff) => begriff.id[0].toLowerCase() === letter
                    ).length === 0 && "hidden lg:flex"
                  } p-10 lg:w-1/2 sm:p-16 space-y-5 lg:bg-glossarBG ${"bg-glossarBG"}`}
                >
                  {begriffe
                    .filter((begriff) => begriff.id[0].toLowerCase() === letter)
                    .map((begriff) => (
                      <div key={`text-${begriff.id}`}>
                        <Description current={current} begriff={begriff} />
                      </div>
                    ))}
                </div>
                <div
                  className={`w-full ${
                    abkuerzungen.filter(
                      (begriff) => begriff.id[0].toLowerCase() === letter
                    ).length === 0 && "hidden lg:flex"
                  } p-10 lg:bg-white ${"bg-white"} lg:w-1/2 space-y-5 sm:p-16 `}
                >
                  {abkuerzungen
                    .filter((abk) => abk.id[0].toLowerCase() === letter)
                    .map((abk) => (
                      <div key={`text-${abk.id}`}>
                        <Description current={current} begriff={abk} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>
      <ContactButton startingY={50} />
      <LanguageSwitcher />
      <ToTop />

      <Footer />
    </PageTemplate>
  );
}

function Description({
  begriff,
  current,
}: {
  begriff: { name: ReactNode; description: ReactNode; id: string };
  current: string;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (current === begriff.id) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [current, begriff.id]);

  return (
    <p className="text-lg text-darkPurple" ref={ref}>
      <span className="font-bold">{begriff.name}</span>
      <br />
      <span>{begriff.description}</span>
    </p>
  );
}
