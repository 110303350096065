import { Trans, useTranslation } from "react-i18next";
import tools from "../../assets/toolsmanagementinstrumente.svg";
import { NavBar } from "../../global/components/NavBar";
import { Section } from "../home/components/Section";

export function StartingSection() {
  // only imported to trigger rerender when language changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  return (
    <Section>
      <div
        style={{
          background:
            "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
        }}
        className="absolute w-[45%] h-[150px] top-56 lg:top-28 lg:left-1/4 right-5   z-10 bg-fullGreen"
      />
      <div className="flex flex-col items-center w-full bg-darkPurple">
        <NavBar current="tools" />
        <div className="flex flex-col-reverse w-full max-w-7xl bg-darkPurple lg:flex-row grow">
          <div className="flex items-center justify-center p-10 xl:w-2/5 lg:w-1/2 sm:p-16 ">
            <img
              src={tools}
              className="max-w-[400px] max-h-[400px] lg:max-h-[550px]"
              alt="tools"
            />
          </div>
          <div className="z-0 flex flex-col justify-center w-full h-full p-10 pt-24 pb-0 text-3xl xl:w-3/5 lg:w-1/2 bg-darkPurple text-lightPurple sm:px-16 lg:py-16">
            <p className="mb-10 lg:mt-20">
              <Trans i18nKey="tools.startingSection" />
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
}
