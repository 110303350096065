import { Modal, Stack } from "@mantine/core";
import { motion } from "framer-motion";
import { useState } from "react";
import Draggable from "react-draggable";
import { ReactComponent as PaperPlaneGreen } from "../../../../assets/04_Icons/paperplane_green.svg";
import { ReactComponent as PaperPlaneWhite } from "../../../../assets/04_Icons/paperplane_white.svg";

import { useTranslation } from "react-i18next";
import { Link } from "./Link";

const boxVariants = {
  initial: { scale: 1 },
  animate: { scale: [0.8, 1.1, 1], transition: { duration: 0.5 } },
  whileTap: { scale: 0.8 },
};
export function ContactButton({ startingY = 150 }: { startingY?: number }) {
  const [isClicked, setIsClicked] = useState(false);
  const [opened, setOpened] = useState(false);

  const { t } = useTranslation();
  return (
    <>
      <Modal
        classNames={{
          content:
            "bg-fullGreen rounded-none shadow-lg relative text-darkPurple",
          body: "pt-36 flex justify-center pb-12 px-10",
        }}
        withCloseButton={false}
        trapFocus={false}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <div className="absolute top-0 flex flex-row items-center select-none left-4 handle ">
          <div>
            <PaperPlaneWhite className="text-white translate-y-3 w-28 h-28" />
          </div>
        </div>
        <Stack className="text-2xl" spacing={2}>
          <p className="font-serif text-2xl font-bold">Dr. Philipp Güther</p>
          <a
            className="text-xl hover:text-darkPurple sm:text-2xl text-lightPurple"
            href="mailto:hello@chameleonconcepts.eu"
          >
            hello@chameleonconcepts.eu
          </a>

          <a
            className="text-xl hover:text-darkPurple sm:text-2xl text-lightPurple"
            href="tel:+49 1590 6792 952"
          >
            +49 1590 6792 952
          </a>
        </Stack>
      </Modal>
      <Draggable
        handle="strong"
        bounds="html"
        defaultPosition={{ x: 0, y: startingY }}
        scale={1}
      >
        <div className="fixed z-50 flex flex-row items-center select-none handle right-8 ">
          <strong>
            <motion.div
              variants={boxVariants}
              initial="initial"
              animate={isClicked ? "animate" : "initial"}
              whileTap="whileTap"
              onPointerDown={() => setIsClicked(false)}
              onPointerUp={() => setIsClicked(true)}
            >
              {
                <PaperPlaneGreen className="translate-y-3 text-fullGreen w-28 h-28" />
              }
            </motion.div>
          </strong>
          <div className="w-[33px]">
            <Link
              onClick={() => {
                setOpened(true);
              }}
              href=""
              variant="purple"
            >
              {t("home.Kontakt")}
            </Link>
          </div>
        </div>
      </Draggable>
    </>
  );
}
