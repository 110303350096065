import { useTranslation } from "react-i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export function LanguageSwitcher() {
  const { i18n } = useTranslation();

  return (
    <div className="absolute z-50 flex flex-row items-end cursor-pointer select-none group right-6 top-6 ">
      <p className="text-xl font-bold text-lightPurple">
        <span
          onClick={() => {
            i18n.changeLanguage("de");
          }}
          className={`hover:text-fullGreen ${
            i18n.resolvedLanguage === "de" && "text-fullGreen"
          }`}
        >
          DE
        </span>{" "}
        |{" "}
        <span
          onClick={() => {
            i18n.changeLanguage("en");
          }}
          className={`hover:text-fullGreen ${
            i18n.resolvedLanguage === "en" && "text-fullGreen"
          }`}
        >
          EN
        </span>
      </p>
    </div>
  );
}
