import { ReactNode } from "react";
export const abkuerzungen_english: {
  id: string;
  name: ReactNode;
  description: ReactNode;
}[] = [
  {
    id: "BIM",
    name: "BIM",
    description:
      "BIM - Building Information Modeling: A working methodology in which various project actors enrich a 3D visualized building model with alphanumeric contextual information and documents over the entire life cycle. The aim is to create a common information and communication environment for all stakeholders around the building.",
  },
  {
    id: "CoE Principle",
    name: "CoE Principle ",
    description:
      "Comply-or-Explain principle: According to the Comply-or-Explain Principle, actors indicate whether they agree with the respective legal requirements and implement them accordingly (comply) or do not comply with them. If they do not comply, they must give significant reasons for the decision (explain).",
  },
  {
    id: "CO2e",
    name: (
      <span>
        CO<sub>2</sub>e
      </span>
    ),
    description: (
      <>
        <span>
          CO<sub>2</sub>-Equivalent
        </span>
        : see under "Key terms".
      </>
    ),
  },
  {
    id: "CRREM",
    name: "CRREM",
    description:
      "Carbon Risk Real Estate Monitor: This tool can be used to record emissions and energy consumption for individual properties or even the entire Portfolio and to assess benchmarks for general development specifications or comparative portfolios. It can also derive risk management indicators for reporting and other reporting.",
  },
  {
    id: "CSR",
    name: "CSR",
    description:
      "CSR (Corporate Social Responsibility): It is the management approach of business ethics. The company sees itself as part of society. It is responsible for the prosperity and well-being of a society worth living in through ecologically, socially, and economically sustainable corporate behavior.",
  },
  {
    id: "CSRD",
    name: "CSRD",
    description:
      "Corporate Sustainability Reporting Directive: EU Directive 2022/2464 is aimed, in particular, at all companies and institutions of public interest. It is similar to the Sustainable Finance Disclosure Regulation under the EU Taxonomy. The report must provide information on the impact of the company's activities on sustainability aspects, as well as its understanding of the impact of sustainability aspects on the development of the business, the results of the business, and the position of the company.",
  },
  {
    id: "CSR-DIA",
    name: "CSR-DIA",
    description: 'CSR Directive Implementation Act: see under "Key terms".',
  },
  {
    id: "DNK",
    name: "DNK",
    description:
      "German Sustainability Code: It is the criteria database for developing a sustainability strategy and reporting in large and small, public and private companies that want to inform their stakeholders about their sustainability performance.",
  },
  {
    id: "DNSH Principle",
    name: "DNSH Principle",
    description: (
      <span>
        Do-No-Significant-Harm Principle: The DNSH Principle is explained by EU
        Regulation 2020/852 as a principle according to which an economic
        activity or entrepreneurial activity is taxonomy-compliant if it makes a
        significant contribution to at least one of a total of six environmental
        goals without violating any other of these six goals. The environmental
        objectives of the taxonomy are (1)&nbsp;climate change mitigation,
        (2)&nbsp;adaptation to climate change, (3)&nbsp;sustainable use of water
        resources, (4)&nbsp;transition to a circular economy, (5)&nbsp;pollution
        prevention, and (6)&nbsp;protection of ecosystems and biodiversity. By
        this it's a framework to facilitate sustainable investment.
      </span>
    ),
  },
  {
    id: "ESEF",
    name: "ESEF",
    description:
      "The European Single Electronic Format is an EU-wide uniform electronic reporting format that issuers whose securities are listed within the EU are required to use in corporate reporting since 2020. The digital reporting format facilitates the accessibility, analysis, and comparability of annual financial reports.",
  },
  {
    id: "ESG",
    name: "ESG",
    description:
      "Environment, Social, Governance: Management approach to developing sustainable asset classes, to meet the increasing demand for sustainable investment opportunities. Environmental aspects (emissions, pollution, etc.), social aspects (occupational safety, protection against child labor, etc.), and aspects of corporate governance (corporate values, quality management, etc.) are assessed and weighted by investors, business partners, or consumers.",
  },
  {
    id: "ESRS",
    name: "ESRS",
    description:
      "European Sustainability Reporting Standards: These guidelines define the content for sustainability reporting. These are sustainability issues in the company that can impact people or the environment (impact materiality) and significant financial materiality.",
  },
  {
    id: "GRI",
    name: "GRI",
    description:
      "The Global Reporting Initiative publishes guidelines for the preparation of sustainability reports. The international initiative sets sustainability standards for reporting, which large global companies mainly use.",
  },
  {
    id: "LCA",
    name: "LCA",
    description: 'Life Cycle Assessments: see under "Key terms".',
  },
  {
    id: "NAP",
    name: "NAP",
    description:
      "National Action Plan on Business and Human Rights: The plan sets standards and thus anchors the responsibility of German companies along supply and value chains. This corporate due diligence extends to strengthening human rights and socially responsible globalization.",
  },
  {
    id: "PAI",
    name: "PAI",
    description: 'Principal Adverse Impacts: see under "Key terms".',
  },
  {
    id: "RTS",
    name: "RTS",
    description:
      "Regulatory Technical Standards: The standards specify the information that must be included in a financial product's pre-contractual and periodic documentation. The aim is to curb greenwashing. To this end, the aim is now to achieve uniform labeling. This label identifies generally binding indicators, which always lead to the principal adverse impacts (see PAI – Principal Adverse Impacts), and optional indicators for identifying, assessing, and weighing the consideration of additional important adverse impacts.",
  },
  {
    id: "SCA",
    name: "SCA",
    description: 'Supply Chain Due Diligence Act: see under "Key terms".',
  },
  {
    id: "SFDR",
    name: "SFDR",
    description:
      'Sustainable Finance Disclosure Regulation: With the SFDR, the EU regulates "the sustainability disclosure obligations for manufacturers of financial products and financial advisors to end investors", also see under "Disclosure Regulation".',
  },

  {
    id: "TCFD",
    name: "TCFD",
    description:
      "Task Force on Climate-related Financial Disclosures: The task force was established by an international organization to oversee the global financial system. The renowned Task Force recommends that investors and corporations consider the impact of climate change on their financing and how it is raised on the market in their strategic planning and risk management. This means that investors evaluate positive and negative (assumed) behaviors and business models in their decisions. Accordingly, investors should also price this as an opportunity or risk (internalizing externalities).",
  },
];
