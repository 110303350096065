import { Group, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import { TextSection } from "../home/components/TextSection";

export function BIM() {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: bgref } = useParallax<HTMLDivElement>({ speed: lg ? -10 : 0 });
  const { ref: contentref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });

  const { t } = useTranslation();

  return (
    <div className="relative flex justify-center">
      <div className="flex flex-col items-center justify-center w-full bg-darkPurple">
        <div className="relative flex flex-col w-full pt-10 lg:flex-row max-w-[1400px] lg:px-10">
          <div
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            ref={bgref}
            className=" absolute w-[30%] h-[400px] -top-10  lg:-top-0 -left-0 bg-fullGreen"
          />
          <div
            ref={contentref}
            className="relative lg:w-[550px]  sm:p-16 p-10 items-end flex flex-col z-10 bg-white"
          >
            <TextSection title={t("tools.bim.title")}>
              <Trans i18nKey="tools.bim.text" />
            </TextSection>
          </div>
          <Stack className="p-10 max-w-[700px] md:p-16">
            <p className="text-lg font-bold text-white">
              {t(
                "tools.bim.Immer auf dem Stand aktuelle Informationen mit einem Klick"
              )}
            </p>
            <BulletPoint
              title={t("tools.bim.Umfassendes Informations\u00ADsystem –")}
            >
              {t(
                "tools.bim.für das komplette Bauwerk und seinen gesamten Lebenszyklus"
              )}
            </BulletPoint>
            <BulletPoint
              title={t("tools.bim.Einfache Informations\u00ADbeschaffung –")}
            >
              {t("tools.bim.für alle Akteurinnen")}
            </BulletPoint>
            <BulletPoint
              title={t("tools.bim.Durchgängiger Informations\u00ADfluss –")}
            >
              {t(
                "tools.bim.dank individuell aufgesetztem Informations\u00ADbeschaffungsprozess und optimal angepassten Informationsschnittstellen"
              )}
            </BulletPoint>
            <BulletPoint
              title={t("tools.bim.Transparente Nachhaltigkeits\u00ADdaten –")}
            >
              {t("tools.bim.für zukunftssichere Managemententscheidungen")}
            </BulletPoint>

            <BulletPoint
              title={t(
                "tools.bim.Effiziente Nachhaltigkeits\u00ADbericht\u00ADerstattung –"
              )}
            >
              {t("tools.bim.dank Building Information Modeling (BIM)")}
            </BulletPoint>
          </Stack>
        </div>
      </div>
    </div>
  );
}
function BulletPoint({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <Stack spacing={3}>
      <Group className="items-start text-3xl flex-nowrap text-fullGreen">
        <p>{">"}</p> <p className="text-3xl text-fullGreen">{`${title}`}</p>
      </Group>
      <p className="text-lg text-white ml-9 ">{children}</p>
    </Stack>
  );
}
