import { Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import profit from "../../../assets/profit.svg";
import { TextSection } from "../../home/components/TextSection";

export function Profit() {
  const { t } = useTranslation();
  return (
    <div className="relative lg:-mt-[140px] lg:-mb-[20px] flex justify-center">
      <div className="flex flex-col-reverse items-center w-full justify-evenly lg:flex-row lg:pr-32">
        <img src={profit} className="max-w-[600px]" alt="Barchart" />
        <div className="  lg:w-[500px] z-30 sm:p-16 p-10 bg-lightGreen">
          <TextSection title={t("services.profit.title")}>
            {t(
              "services.profit.Für Sie – und für die Welt, in der wir uns bewegen."
            )}
            <br />
            <BulletPoint title={t("services.profit.Hohe Profitabilität")}>
              {t(
                "services.profit.– durch strukturiertes Informations\u00ADmanagement"
              )}
            </BulletPoint>
            <BulletPoint title={t("services.profit.Risikominimierung")}>
              {t(
                "services.profit.– wirtschaftlich, rechtlich, ökologisch und sozial"
              )}
            </BulletPoint>
            <BulletPoint title={t("services.profit.Entscheidungssicherheit")}>
              {t(
                "services.profit.– durch eine umfassende und aktuelle Informationslandschaft"
              )}
            </BulletPoint>
            <BulletPoint title={t("services.profit.ESG-Konformität")}>
              {t(
                "services.profit.– durch eine transparente Datenlage in jeder Lebenszyklus-Phase"
              )}
            </BulletPoint>
            <BulletPoint title={t("services.profit.Rechtssicherheit")}>
              {t(
                "services.profit.– im Sinne der EU-Taxonomie und der Sustainable Finance Disclosure Regulation (SFDR)"
              )}
            </BulletPoint>
          </TextSection>
        </div>
      </div>
    </div>
  );
}

function BulletPoint({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <Group className="items-start my-3 flex-nowrap">
      <p>
        <b>{">"}</b>
      </p>
      <p>
        <b>{title}</b> {children}
      </p>
    </Group>
  );
}
