import { Group } from "@mantine/core";
import { ReactNode } from "react";
export const begriffe_german: {
  id: string;
  name: ReactNode;
  description: ReactNode;
}[] = [
  {
    id: "Active Stewardship",
    name: "Active Stewardship",
    description:
      "Managementansatz, der von Vermögensverwalter:innen, Asset Manager:innen oder auch dem:der Anleger:in selbst verlangt, verantwortungsbewusst Kapital anzulegen, nämlich bewusst in nachhaltige Finanzprodukte. Diese Anleger:innen agieren bei ihren Investitionsentscheidungen am Kapitalmarkt proaktiv und lösen dabei einen scheinbaren Widerspruch: Die Zielsetzungen eines ESG-konformen und finanziell attraktiven, wertsteigernden Portfolios sind vereinbar.",
  },

  {
    id: "Betreiberverantwortung",
    name: "Betreiberverantwortung",
    description: (
      <span>
        Betreiber:innen einer Immobilie sind neben Eigentümer:innen und
        Besitzer:innen ggf. auch Unternehmen sowie einzelne Mitarbeiter:innen,
        die ein Gebäude bzw. eine gebäudetechnische Anlage betreiben. Denn
        Betreiberpflichten können übertragen werden. Betreiberverantwortung
        befasst sich mit „Betreiberpflichten” und „Unternehmerpflichten”. Diese
        Pflichtenhefte formulieren Aufgaben, die als Betreiberverantwortung für
        eine Immobilie zu verstehen sind. So darf einerseits von der Immobilie
        und deren Ausstattung keine Gefahr ausgehen
        (Verkehrs&shy;sicherungs&shy;pflicht), andererseits müssen aber auch
        weitere rechtliche Vorgaben bspw. an Arbeitsstätten eingehalten und
        seitens des:der Betreiber:in der Immobilie gewährleistet werden.
        Ebenfalls ist nicht nur Schaden von Personen durch das
        verantwortungsvolle Handeln abzuwenden, sondern auch von Sachen. Somit
        unterliegen Emissionen ebenfalls der Betreiberverantwortung, um die
        Immobilienumwelt nicht zu schädigen.
      </span>
    ),
  },
  {
    id: "CO2-Äquivalente",
    name: (
      <span>
        CO<sub>2</sub>-Äquivalente
      </span>
    ),
    description: (
      <span>
        CO<sub>2</sub>-Äquivalente (CO<sub>2</sub>e) sind die Maßeinheit zur
        Vereinheitlichung der Klimawirkung unterschiedlicher Treibhausgase, zu
        denen neben CO<sub>2</sub> u.a. auch Methan gehört. Sie bestimmt das
        Treibhauspotenzial von Substanzen und berücksichtigt dabei auch die
        Dimension der Zeit.
      </span>
    ),
  },
  {
    id: "CSR-Richtlinie-Umsetzungsgesetz",
    name: "CSR-Richtlinie-Umsetzungsgesetz",
    description:
      "CSR-Richtlinie-Umsetzungsgesetz (CSR-RUG) erläutert die Berichtspflicht über nichtfinanzielle Aspekte, wie Umwelt-, Arbeitnehmer- und Sozialbelange, zur Achtung der Menschenrechte und zur Bekämpfung von Korruption und Bestechung. Diese Aspekte sind hinsichtlich des Geschäftsmodells, des Geschäftsergebnisses, der Strategiekonzepte zu erläutern, aber auch wesentliche Risiken und Leistungsindikatoren sind darzulegen.",
  },
  {
    id: "Digitaler Zwilling",
    name: "Digitaler Zwilling",
    description:
      "Visualisiertes Gebäudemodell als Abbild eines physisch realen Gebäudes, das zum dynamischen und fortgeschriebenen Informations- und Berechnungsmodell wird. Dabei werden ständig digitale Daten aus der technischen Gebäudeausstattung im Gebäude übermittelt und verschiedenste visuelle und numerische Simulationen im Zwilling durchgeführt, um gegenwärtige Einflüsse und Nutzungsarten des Gebäudes für das zukünftige Verhalten zu prognostizieren.",
  },
  {
    id: "EU-Taxonomie",
    name: "EU-Taxonomie",
    description: (
      <div>
        Die Taxonomie (Verordnung 2020/852 der EU) ist ein einheitliches
        Klassifikationssystem, um über nachhaltige Wirtschaftsaktivitäten in
        Investmentanlagen, Portfolios und Geschäftsbereichen transparent und
        vergleichbar zu informieren. Die Taxonomie kategorisiert die
        Wirtschaftsaktivitäten wesentlicher Branchen, die für eine große Menge
        des CO<sub>2</sub>-Ausstoßes verantwortlich sind, und definiert
        Schwellenwerte, um sie als ökologisch nachhaltig einzustufen. Danach
        erfolgt die Bewertung, ob wirtschaftliche Aktivitäten unter den vier
        folgenden Voraussetzungen ökologisch nachhaltig sind: <br />
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            substanzieller Beitrag zur Erreichung eines der EU-Umweltziele;
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            Vermeidung erheblicher Verletzung der anderen EU-Umweltziele;
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            Einhaltung der Mindestanforderungen in den Bereichen Governance,
            Menschen- und Arbeitnehmerrechte;
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            Erfüllung der vorgegebenen technischen Bewertungskriterien.
          </span>
        </Group>
      </div>
    ),
  },
  {
    id: "Graue Emissionen",
    name: "Graue Emissionen",
    description:
      'Als graue Emissionen werden bei der Öko-Bilanzierung von Immobilien diejenigen klimaschädlichen Emissionen bezeichnet, die durch Herstellung, Transport und Entsorgung der Baumaterialien verursacht werden. Emissionen also, die nicht erst durch den Bau und Betrieb emittiert werden, sondern gewissermaßen "verbaut" sind.',
  },
  {
    id: "Klimaneutralität",
    name: "Klimaneutralität",
    description: (
      <>
        „Klimaneutral“ im strengen Sinn bezeichnet Aktivitäten, die das Klima
        nicht beeinflussen. Der Begriff wird jedoch oft unscharf genutzt.
        Korrekterweise ist zu unterscheiden zwischen:
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            „CO
            <sub>2</sub>-neutral“ für Aktivitäten, die keine CO
            <sub>2</sub>-Emissionen verursachen{" "}
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          <span>
            „CO
            <sub>2</sub>e-neutral“, „treibhausgasneutral“,
            „Netto-Null-Emissionen“ oder „Null-Klimagas-Emissionen“ für
            Aktivitäten, die keine Treibhausgas-Emissionen verursachen
          </span>
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          „klimaneutral“ für Aktivitäten, die das Klima nicht beeinflussen.
        </Group>
        Hinter dem Label „klimaneutral“ von Produkten oder Organisationen
        verbergen sich unterschiedliche Nachhaltigkeitsmaßnahmen. Sie reichen
        von Kompensationszahlungen bspw. in Form von CO<sub>2</sub>-Zertifikaten
        bis zur Umsetzung von technischen und biologischen Maßnahmen zur
        Reduzierung der eigenen Emissionen bzw. Absorption von klimaschädlichen
        Gasen.
      </>
    ),
  },
  {
    id: "Lieferkettensorgfaltspflichtengesetz",
    name: <span>Lieferketten&shy;sorgfalts&shy;pflichten&shy;gesetz</span>,
    description: (
      <span>
        Das seit dem 01. Januar 2023 in Deutschland in Kraft getretene
        Lieferketten&shy;sorgfalts&shy;pflichten&shy;gesetz, kurz:
        Lieferkettengesetz, regelt die unternehmerische Verantwortung in
        Deutschland für die Einhaltung von Menschenrechten in den globalen
        Lieferketten. Das heißt, dass die Unternehmen die Risiken von Verstößen
        entlang ihrer gesamten Lieferkette verantworten. Somit sollten
        Unternehmen zunächst diese Risiken in ihren Lieferketten ermitteln,
        bewerten und priorisieren. Aufbauend auf den Ergebnissen müssen dann
        eine Grundsatzerklärung veröffentlicht und Maßnahmen ergriffen werden,
        um Verstöße gegen die Menschenrechte sowie Schädigungen der Umwelt zu
        vermeiden oder zu minimieren. Das Gesetz legt dar, welche Präventions-
        und Abhilfemaßnahmen notwendig sind. Zu den weiteren Pflichten gehören
        auch die regelmäßige Berichterstattung über das Lieferkettenmanagement.
      </span>
    ),
  },
  {
    id: "Offenlegungsverordnung",
    name: "Offenlegungsverordnung",
    description: (
      <span>
        Die Europäische Union hat mit der Verordnung 2019/2088 Rahmenbedingungen
        für eine transparente Berichterstattung zu Nachhaltigkeitsrisiken am
        Kapitalmarkt benannt. Die Offenlegungen von Informationen gegenüber
        Finanzmarktteilnehmer:innen über die Einbeziehung von
        Nachhaltigkeitsrisiken, über die Berücksichtigung nachteiliger
        Nachhaltigkeitsauswirkungen und nachhaltiger Investitionsziele oder über
        die Bewerbung ökologischer oder sozialer Merkmale bei
        Investitionsentscheidungen und im Beratungsprozess sind Zielsetzung der
        Verordnung. Darüber hinaus ist es erklärtes Ziel der Verordnung, Kapital
        im Finanzdienst&shy;leistungssektor zu mobilisieren, das in Maßnahmen
        bzw. Investitionen fließt, die nachhaltig sind oder
        nachhaltigkeitsbezogene Lösungen darstellen. Hierbei handelt es sich um
        die sogenannten Artikel-8- und Artikel-9-Produkte, die in der Verordnung
        definiert werden.
      </span>
    ),
  },
  {
    id: "oekobilanz",
    name: "Ökobilanz",
    description: (
      <p>
        Die Ökobilanzierung ist ein Verfahren, um umweltrelevante Vorgänge zu
        erfassen und zu bewerten. Ökobilanzen (life cycle assessments, kurz LCA)
        betrachten medienübergreifend (u.a. Schadwirkungen in Boden, Luft,
        Wasser) und stoffstromintegriert (u.a. Verbrauch und Emissionen in
        Versorgungs- und Entsorgungsprozessen). Zur Oköbilanzierung gehören in
        der Regel: 1.&nbsp;Definition von Ziel und Untersuchungsrahmen,
        2.&nbsp;Sachbilanz, 3.&nbsp;Wirkungsabschätzung, 4.&nbsp;Auswertung.
      </p>
    ),
  },
  {
    id: "Principal Adverse Impacts",
    name: "Principal Adverse Impacts",
    description: (
      <p>
        Die Principal Adverse Impacts (PAI) haben sich über alle drei
        Dimensionen des ESG-Managementansatzes zu erstrecken. Diese
        nachhaltigkeits&shy;schädlichen Einflüsse sind seitens der Investoren
        bei ihren Investitionsentscheidungen zu analysieren, zu benennen und
        offenzulegen. Mehr noch sind Erklärungen ab- und Priorisierung
        anzugeben, wie die Einflüsse quantitativ messbar reduziert werden
        sollen, welche Maßnahmen ergriffen werden sollen, um die Zielwerte zu
        erreichen. Es gibt aber auch gewisse Mindestausschlüsse und{" "}
        <span className="whitespace-nowrap">-anforderungen</span> an die
        Investitions&shy;entscheidung.
      </p>
    ),
  },
  {
    id: "Scope",
    name: "Scope",
    description: (
      <span>
        In der Nachhaltigkeitsdebatte werden Treibhausgas-Emissionen in drei
        Geltungsbereiche, sogenannte Scopes, unterteilt.
        <br />
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          Scope I erfasst alle Emissionen, die direkt durch ein Unternehmen in
          seinen Leistungs&shy;erstellungs&shy;prozessen bzw.
          Geschäftstätigkeiten verursacht, gesteuert und damit verantwortet
          werden.
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          Scope II erfasst bestimmte Emissionen, die indirekt bzw. bedingt durch
          ein Unternehmen gesteuert und verantwortet werden können. Hier wird im
          Wesentlichen der Verbrauch von Energie zum Betrieb der Betriebsobjekte
          subsumiert. Die Emissionen werden also nicht vom Unternehmen selbst
          verursacht.
        </Group>
        <Group className="items-start flex-nowrap">
          <span>{">"}</span>
          Scope III schließlich erfasst weitere indirekte Emissionen, die weder
          vom Unternehmen verursacht noch gesteuert werden können, aber indirekt
          zu verantworten sind. Dies sind Emissionen, die durch die Nutzung der
          vom Unternehmen erstellten und angebotenen Sach- und Dienstleistungen
          verursacht werden oder aber auch in vorgelagerten Wertschöpfungsstufen
          außerhalb des Unternehmens bei Lieferanten herbeigeführt werden.
        </Group>
      </span>
    ),
  },

  {
    id: "Use Case",
    name: "Use Case",
    description:
      "Der Use Case (Anwendungsfall) bestimmt, wofür die BIM-Arbeitsmethodik benötigt wird. Erklärt: Was will ich damit erreichen bzw. welche Geschäftstätigkeit soll in digital strukturierten Prozessen im Gebäudemodell umgesetzt werden?",
  },
  {
    id: "Wesentlichkeitsanalyse",
    name: "Wesentlichkeitsanalyse",
    description:
      "Die Wesentlichkeitsanalyse setzt sich mit den Auswirkungen der Tätigkeiten eines Unternehmens auf seine Umgebung auseinander, betrachtet gleichzeitig aber auch die Einwirkungen der Umgebung auf das Unternehmen. Die direkten und indirekten Umwelt- und Sozialeinflüsse hinsichtlich der Nachhaltigkeit werden nach deren positiven und negativen Wirkungen analysiert und deren Erkenntnisse auf die eigenen Prozesse übertragen.",
  },
  {
    id: "Zertifizierungssysteme",
    name: "Zertifizierungssysteme",
    description:
      "Bereits seit einigen Jahren werden Immobilieneigentümer:innen auf dem nationalen und internationalen Immobilienmärkten Zertifizierungs- und Bewertungsysteme hinsichtlich Nachhaltigkeitskriterien angeboten. Gängig sind hier insbesondere die Zertifikate DGNB, BREEAM, LEED. Somit können diese eine gute Orientierungshilfe für die Informationsziele der Stakeholder einer Immobilie zur Erfüllung der Zielvorgaben der EU-Taxonomie sein.",
  },
];
