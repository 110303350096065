import { useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import tools from "../../assets/toolscamera.svg";
import { Section } from "../home/components/Section";
import { TextSection } from "../home/components/TextSection";

export function Karriere() {
  const { ref } = useParallax<HTMLDivElement>({
    speed: -10,
  });

  const { t } = useTranslation();

  return (
    <Section>
      <div className="flex flex-col items-center justify-center w-full lg:overflow-x-hidden bg-darkPurple">
        <div className="relative flex flex-row-reverse w-full max-w-6xl lg:py-10 text-lightPurple lg:px-10">
          <div
            ref={ref}
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            className=" absolute w-[30%] h-[400px] -top-12  lg:-top-8 lg:right-0 right-10 bg-fullGreen"
          />
          <div className="relative lg:w-[550px]  sm:p-16 p-10 items-end flex flex-col bg-fullGreen">
            <TextSection title={t("about.karriere.Karriere")}>
              {t("about.karriere.text")}
            </TextSection>
          </div>
          <img
            className=" hidden lg:block  z-10 -mr-0  max-w-[60%] "
            src={tools}
            alt="meeting"
          />
        </div>
        <img
          className=" lg:hidden max-w-[800px] w-full object-cover object-top"
          src={tools}
          alt="meeting"
        />
      </div>
    </Section>
  );
}
