import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { About } from "./pages/about/About";
import { Datenschutz } from "./pages/datenschutz/Datenschutz";
import { Home } from "./pages/home/Home";
import { Impressum } from "./pages/impressum/Impressum";
import { Insights } from "./pages/insights/Insights";
import { Services } from "./pages/services/Services";
import { Tools } from "./pages/tools/Tools";

import "./global/utils/setupLocalization";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/tools",
    element: <Tools />,
  },
  {
    path: "/insights",
    element: <Insights />,
  },
  {
    path: "/impressum",
    element: <Impressum />,
  },
  {
    path: "/datenschutz",
    element: <Datenschutz />,
  },
]);

export function App() {
  return (
    <ParallaxProvider>
      <RouterProvider router={router} />
    </ParallaxProvider>
  );
}
