import { useMediaQuery } from "@mantine/hooks";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import { NavBar } from "../../../global/components/NavBar";
import { Section } from "../../home/components/Section";
import { TextSection } from "../../home/components/TextSection";

export function StartingSection() {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: zitatref } = useParallax<HTMLDivElement>({ speed: lg ? 10 : 0 });
  const { ref: balkenref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });

  const { t } = useTranslation();

  return (
    <Section>
      <div
        style={{
          background:
            "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
        }}
        className="absolute w-[45%] h-[150px] top-64 lg:top-28 lg:left-1/4 right-5   z-10 bg-fullGreen"
      />
      <div className="flex flex-col w-full">
        <div
          ref={balkenref}
          className="absolute left-0 hidden w-8 lg:flex bg-lightGreen h-1/2 top-2/3"
        />
        <NavBar current="services" />
        <div className="flex flex-col w-full mt-10 lg:flex-row grow">
          <div className="p-10 xl:w-2/5 lg:w-1/2 lg:pt-32 sm:p-20 lg:pl-32 sm:pr-24">
            <TextSection title={t("services.startingSection.title")}>
              <Trans i18nKey="services.startingSection.anleser"></Trans>
            </TextSection>
          </div>
          <div
            ref={zitatref}
            className="xl:w-3/5 lg:w-1/2 flex-col w-full z-0 lg:-mt-[80px] bg-darkPurple text-lightPurple text-3xl flex items-center justify-center sm:p-20 p-10  h-full"
          >
            <div className="text-3xl ">
              {t("services.startingSection.citation")}
              <p className="mt-2 text-xl text-white">Philipp</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
