import { Section } from "../Section";
import { Logo } from "./Logo";
import { NavbarLinks } from "./NavbarLinks";
import { Slogan } from "./Slogan";

export function StartingSection() {
  return (
    <Section>
      <NavbarLinks />
      <Logo />
      <Slogan />
    </Section>
  );
}
