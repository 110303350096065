export const services_english = {
  startingSection: {
    title: "Customized Information Landscape",
    anleser:
      "Building owners but also society demand high standards of real estate, whether that is housing or commercial. The confirmation that the real estate is planned, constructed, operated, <strong>safe</strong>, and <strong>sustainable</strong> has to be <strong>maintained at all time</strong>. That's the <strong>intention of the EU Taxonomy</strong> and <strong>the investors' requirement</strong> today.<br/><br/>We create an <strong>efficient and functional</strong> information landscape. This tool links all the actors and offers all data of the real estate project <strong>widespread and transparent</strong>: People – technique – processes – frame conditions.<br/><br/>So, you can avoid interruptions in your project that can increase your costs. Your project keeps running economically, sustainably, and according to the legislative framework.",
    citation:
      "»Trustable data and information are a mission-critical resource in tomorrow's business world. Also, especially in the complex real estate branch.«",
  },
  profit: {
    title: "Consultancy, which is paying off!",
    "Für Sie – und für die Welt, in der wir uns bewegen.":
      "For you - and the world we are living in!",
    "Hohe Profitabilität": "Higher profitability",
    "– durch strukturiertes Informations\u00ADmanagement":
      "– by structured information management",
    Risikominimierung: "Minimizing risks",
    "– wirtschaftlich, rechtlich, ökologisch und sozial":
      "– economically, legally, ecologically, and socially",
    Entscheidungssicherheit: "Confident decisions ",
    "– durch eine umfassende und aktuelle Informationslandschaft":
      "– by comprehensive and actual information landscape",
    "ESG-Konformität": "ESG-compliant",
    "– durch eine transparente Datenlage in jeder Lebenszyklus-Phase":
      "– by a transparent data availability at any time of the life cycle",
    Rechtssicherheit: "Legal certainty",
    "– im Sinne der EU-Taxonomie und der Sustainable Finance Disclosure Regulation (SFDR)":
      "– according to the EU-Taxonomy and the Sustainable Finance Disclosure Regulation (SFDR)",
  },

  beratung: {
    citation:
      "»According to our claim, we are creating an information landscape, in which all project stakeholders move forward efficiently: individual and as a project-team.«",
    Beratung: "Consultancy",
    text: "Our advisory process is always starting with a widespread and <strong>systemic analysis</strong>. First, we investigate your <strong>need for information</strong>: Which legal or engineering standards and KPIs are relevant to your portfolio? Which data and documents are necessary for you and your stakeholders? Furthermore, what are the <strong>specific conditions</strong> of the market your organization is working in? Which resources are available for you? How are you working? How are your teams working? Which systems are you using?<br /> <br /><strong>The individually fitted information landscape</strong><br />This analysis is the basis for us to build a <strong>company-specific portfolio</strong> and <strong>project-specific information system</strong>: multilayered but simple. We are using real estate-specific analyzing tools invented by our team: our Chameleon Concepts.<br /> <br />The result:  The custom-fit information landscape and the optimized information sourcing process for your business - 360° all-round view and along the whole life cycle.",
  },
  pm: {
    citation:
      "»We understand our project management as an information management for successful and sustainable real estate projects.«",
    title: "Project management",
    text: "Even if it is a new building, refurbishment, or operation, a building project is <strong>dynamic in a dynamic surrounding</strong>. During his life cycle, many companies and actors are involved. Therefore, single process steps have to be <strong>adjusted from time to time</strong>. Cooperative, collaborative, and connective. It must use transparent working methods and a <strong>responsive management system</strong> with short sprints instead of fixed processes and workflows.<br /> <br /><strong>Agile and adaptable</strong><br />For you, we're putting in place a <strong>flexible project management system</strong>, based on methods like BIM and Scrum. This system immediately links all parties. <strong>Open communication among all team members</strong> is still crucial. We put a lot of emphasis on <strong>cooperating closely as a temporary team</strong> to come up with <strong>workable solutions</strong>. Our plans will be routinely reviewed and updated so that any changes may be easily accommodated.",
  },
  im: {
    "Agiles Informations\u00ADmanagement für Zukunftsprojekte":
      "Agile information management for future projects",
    "Dafür sorgen wir": "That's what we are taking care of:",
    "schneller Informationsfluss": "Quicker information flow",
    "integrative Zusammenarbeit": "Integrative collaboration",
    "produktives, offenes Kommunikationsumfeld":
      "Productive and open communication",
    "kurze und sichere Kommunikationswege":
      "Short and safe communication paths",
    "optimale, flexible Arbeitsabläufe": "Optimized and flexible workflows",
    "Das bringt's": "That's your profit:",
    "hohe Anpassungsfähigkeit": "Higher adaptability",
    "transparente Kosten": "Transparent costs",
    "präzise Kalkulationen": "Precise calculation",
    "pragmatische Lösungen": "Pragmatic solutions",
    "kürzere Projektzeiten": "Shorter project times",
  },
  schulung: {
    text: "Employees who know and understand the project's methods and targets are working more <strong>efficiently and target-oriented</strong> for the common project. We concept our training, <strong>especially for your team and your projects</strong>: we adjust the contents and methods according to the group's composition and the participants' knowledge. By this, our trainings are reaching the highest profit. This benefits your employees, your project, and your business.",
    title: "Training",
    teaser:
      "<strong>Integrated or separate</strong> <br /> As part of our project management or as an independent course, our benefits will support you at any phase of your project.",
  },
};
