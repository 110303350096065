import { Stack } from "@mantine/core";

export function ToTop() {
  return (
    <div
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      className="fixed z-50 flex flex-row items-end cursor-pointer select-none group lg:right-10 right-6 bottom-10 "
    >
      <div className="w-1.5 h-10 mb-1 group-hover:bg-fullGreen bg-lightPurple"></div>
      <Stack
        spacing={1}
        className="ml-2 text-5xl font-bold group-hover:text-fullGreen text-lightPurple"
      >
        <p className="-mb-5 ">^</p>
        <p className="-mb-5 ">^</p>
        <p className="-mb-5 ">^</p>
      </Stack>
    </div>
  );
}
