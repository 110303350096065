import { useNavigate } from "react-router-dom";

export function Link({
  children,
  onliner = false,
  variant,
  href,
  onClick,
}: {
  children: string;
  onliner?: boolean;
  href: string;
  variant: "purple" | "green" | "white" | "fullgreen";
  onClick?: () => void;
}) {
  const navigate = useNavigate();
  return (
    <div
      onClick={onClick ? onClick : () => navigate(href)}
      style={{ textOrientation: "sideways", writingMode: "vertical-rl" }}
      className={`flex group  font-bold select-none cursor-pointer h-[90px]   text-xl  ${
        onliner && ""
      } flex-col  ${
        variant === "purple"
          ? "text-lightPurple hover:text-fullGreen "
          : variant === "green"
          ? "text-lightGreen hover:text-lightPurple"
          : variant === "fullgreen"
          ? "text-fullGreen hover:text-lightPurple"
          : "text-white hover:text-lightPurple"
      } rotate-180 `}
    >
      <div
        className={`w-1.5 h-10 mb-1 ${
          variant === "purple"
            ? "group-hover:bg-fullGreen bg-lightPurple"
            : variant === "green"
            ? "group-hover:bg-lightPurple bg-lightGreen"
            : variant === "fullgreen"
            ? "group-hover:bg-lightPurple bg-fullGreen"
            : "group-hover:bg-lightPurple bg-white"
        } `}
      ></div>
      <p className="leading-none tracking-wider px-0.5">{children}</p>
    </div>
  );
}
