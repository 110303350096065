import { useMediaQuery } from "@mantine/hooks";
import { forwardRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import cubes from "../../assets/toolscubes.svg";
import workspace from "../../assets/workspace.jpg";
import { TextSection } from "../home/components/TextSection";

export const Scrum = forwardRef<HTMLDivElement>((_, ref) => {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: imgref } = useParallax<HTMLImageElement>({
    speed: lg ? -10 : 0,
  });
  const { ref: cubesref } = useParallax<HTMLImageElement>({
    speed: lg ? 10 : 0,
  });

  const { t } = useTranslation();

  return (
    <div className="relative flex justify-center lg:-mt-16 bg-darkPurple">
      <img
        ref={cubesref}
        alt="cubes"
        className="absolute top-0 hidden max-w-[200px] right-10 lg:block"
        src={cubes}
      />
      <div className="flex flex-col items-center w-full h-full">
        <img
          className=" lg:hidden max-h-[300px] w-full object-cover object-top"
          src={workspace}
          alt="meeting"
        />
        <div className="relative  w-full lg:min-h-[800px] flex items-end justify-end ">
          <img
            ref={imgref}
            className="absolute hidden mt-[90px] lg:block left-0 top-0 max-w-[50%] object-cover h-full"
            src={workspace}
            alt="workspace"
          />
          <div
            ref={ref}
            className="relative max-w-6xl bg-lightGreen lg:w-[75%]  sm:p-16 p-10 items-end flex flex-col "
          >
            <TextSection title={t("tools.scrum.title")}>
              <Trans i18nKey="tools.scrum.text" />
            </TextSection>
          </div>
        </div>
      </div>
    </div>
  );
});
