import { useParallax } from "react-scroll-parallax";
import { Link } from "../startingSection/Link";

export function BookaMark() {
  const { ref } = useParallax<HTMLDivElement>({
    speed: 10,
  });
  return (
    <div
      ref={ref}
      className="flex -mt-[40px] absolute -top-16 md:left-32 left-8 items-end  h-[250px] w-[110px]  bg-lightGreen"
    >
      <div className="-translate-y-[50px] translate-x-[35px] md:translate-x-[30px]">
        <Link href="/services" variant="white">
          Services & profit
        </Link>
      </div>
    </div>
  );
}
