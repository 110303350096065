import { Group } from "@mantine/core";
import { ReactNode, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../global/components/LanguageSwitcher";
import { NavBar } from "../../global/components/NavBar";
import { PageTemplate } from "../../global/components/PageTemplate";
import { ToTop } from "../../global/components/ToTop";
import { Footer } from "../home/components/Footer";
import { Section } from "../home/components/Section";
import { TextSection } from "../home/components/TextSection";
import { ContactButton } from "../home/components/startingSection/ContactButton";

export function Datenschutz() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Section>
        <div
          style={{
            background:
              "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
          }}
          className="absolute w-[45%] h-[150px] top-56 lg:top-28 lg:left-1/4 right-5   z-10 bg-fullGreen"
        />
        <div className="flex flex-col">
          <NavBar current="" />
          <div className="px-10 my-24 lg:pt-14 sm:px-16">
            <TextSection title={t("privacy.title")}>
              <Trans i18nKey="privacy.p1" />
              <List
                strings={[
                  <span>{t("privacy.l1.b1")}</span>,
                  <span>{t("privacy.l1.b2")}</span>,
                  <span>{t("privacy.l1.b3")}</span>,
                  <span>{t("privacy.l1.b4")}</span>,
                  <span>{t("privacy.l1.b5")}</span>,
                  <span>{t("privacy.l1.b6")}</span>,
                  <span>{t("privacy.l1.b7")}</span>,
                  <span>{t("privacy.l1.b8")}</span>,
                  <span>{t("privacy.l1.b9")}</span>,
                ]}
              />
              <Trans i18nKey="privacy.p2" />
              <List
                strings={[
                  <span>{t("privacy.l2.b1")}</span>,
                  <span>{t("privacy.l2.b2")}</span>,
                  <span>{t("privacy.l2.b3")}</span>,
                  <span>{t("privacy.l2.b4")}</span>,
                ]}
              />
              <Trans i18nKey="privacy.p3" />
              <List
                strings={[
                  <span>{t("privacy.l3.b1")}</span>,
                  <span>{t("privacy.l3.b2")}</span>,
                  <span>{t("privacy.l3.b3")}</span>,
                  <span>{t("privacy.l3.b4")}</span>,
                ]}
              />
              <Trans i18nKey="privacy.p4" />
            </TextSection>
          </div>
        </div>
      </Section>
      <ContactButton startingY={50} />
      <LanguageSwitcher />
      <ToTop />

      <Footer current="datenschutz" />
    </PageTemplate>
  );
}

function List({ strings }: { strings: ReactNode[] }) {
  return (
    <>
      {strings.map((string, index) => (
        <Group key={index} className="items-start flex-nowrap">
          <span>{">"}</span>
          {string}
        </Group>
      ))}
    </>
  );
}
