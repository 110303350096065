import { Link } from "./Link";

export function NavbarLinks() {
  return (
    <div className="absolute  flex md:justify-end items-start px-10 lg:pr-[46px] w-full lg:pt-[66px] pt-10 z-0 bg-[#290A3D] top-0 left-0 h-full md:w-2/3">
      <div className="flex w-[235px] flex-row items-end justify-end space-x-6 ">
        <Link href="/services" variant="purple">
          Services & profit
        </Link>

        <Link href="/tools" variant="purple">
          Tools & systems
        </Link>
        <Link href="/about" variant="purple" onliner>
          About
        </Link>
        <Link href="/insights" variant="purple" onliner>
          Insights
        </Link>
      </div>
    </div>
  );
}
