import { ReactNode } from "react";

export function TextSection({
  title,
  children,
  color = "dark",
  hovered = false,
  hoveredColor = "fullGreen",
  titleColor = "",
}: {
  titleColor?: string;
  title: ReactNode;
  children: ReactNode;
  color?: "dark" | "light";
  hovered?: boolean;
  hoveredColor?: "fullGreen" | "lightPurple";
}) {
  return (
    <div className={`flex  flex-col mb-16 space-y-5 font-serif  last:mb-0 `}>
      <div className="flex flex-col space-y-1">
        <div
          className={`w-12  h-2 ${
            titleColor === "white"
              ? "bg-white"
              : color === "light" && !hovered
              ? "bg-lightPurple"
              : !hovered
              ? "bg-darkPurple"
              : hoveredColor === "lightPurple"
              ? "bg-lightPurple"
              : "bg-fullGreen"
          }`}
        />
        <p
          className={`text-4xl ${typetotitlecolor(
            color,
            hovered,
            hoveredColor
          )} font-bold`}
        >
          <span className={`${titleColor === "white" ? "text-white" : ""}`}>
            {title}
          </span>
        </p>
      </div>
      <div
        className={`font-sans ${
          color === "light" ? "text-lightPurple" : "text-darkPurple"
        } text-lg`}
      >
        {children}
      </div>
    </div>
  );
}

function typetotitlecolor(
  color: "dark" | "light",
  hovered: boolean,
  hoveredColor: "fullGreen" | "lightPurple"
) {
  if (hovered) {
    if (hoveredColor === "lightPurple") return "text-lightPurple";
    return "text-fullGreen";
  }
  switch (color) {
    case "dark":
      return "text-darkPurple";
    case "light":
      return "text-lightPurple";
  }
}
