import { Group } from "@mantine/core";
import informationsmanagement from "../../assets/Informationsmanagement.svg";

import { useTranslation } from "react-i18next";
import { Section } from "../home/components/Section";
export function Informationsmanagement() {
  const { t } = useTranslation();
  return (
    <Section>
      <div className="flex flex-col-reverse w-full lg:flex-row">
        <div className="flex h-full pb-16 sm:pb-0 lg:justify-center lg:items-center lg:bg-lightGreen lg:w-1/2 lg:h-full">
          <img
            src={informationsmanagement}
            className="hidden w-1/2 mb-10 lg:block lg:mb-0 lg:w-2/3"
            alt="Informationsmanagement"
          />
        </div>
        <div className="p-10 text-lg md:px-24 lg:py-24 text-darkPurple">
          <p className="mb-10 text-3xl">
            {t(
              "services.im.Agiles Informations\u00ADmanagement für Zukunftsprojekte"
            )}
          </p>
          <p></p>
          <b>{t("services.im.Dafür sorgen wir")}</b>
          <BulletPoint>
            {t("services.im.schneller Informationsfluss")}
          </BulletPoint>
          <BulletPoint>
            {t("services.im.integrative Zusammenarbeit")}
          </BulletPoint>
          <BulletPoint>
            {t("services.im.produktives, offenes Kommunikationsumfeld")}
          </BulletPoint>
          <BulletPoint>
            {t("services.im.kurze und sichere Kommunikationswege")}
          </BulletPoint>
          <BulletPoint>
            {t("services.im.optimale, flexible Arbeitsabläufe")}
          </BulletPoint>
          <p>
            <b>{t("services.im.Das bringt's")}</b>
            <BulletPoint>
              {t("services.im.hohe Anpassungsfähigkeit")}
            </BulletPoint>
            <BulletPoint>{t("services.im.transparente Kosten")}</BulletPoint>
            <BulletPoint>{t("services.im.präzise Kalkulationen")}</BulletPoint>
            <BulletPoint>{t("services.im.pragmatische Lösungen")}</BulletPoint>
            <BulletPoint>{t("services.im.kürzere Projektzeiten")}</BulletPoint>
          </p>
        </div>
      </div>
    </Section>
  );
}

function BulletPoint({ children }: { children: string }) {
  return (
    <Group className="items-start my-3 flex-nowrap">
      <p>
        <b>{">"}</b>
      </p>
      <p>{children}</p>
    </Group>
  );
}
