export const about_english = {
  intro:
    "»The team of Chameleon Concepts are more than consultants. According to your real estate portfolio, we are supporters and guides: your trailblazer.«",
  "Was die Chameleons anders machen":
    "What are Chameleons doing in another way?",
  "Der Chameleon-Spirit": "The Chameleon Spirit",
  spirit:
    "We are by you and your investment in real estate projects – from development to project management until the operation. We are part of the project team, part of the puzzle: we are working integrative – on-site with you and for you. This is our Chameleon Spirit.",
  "systemisch betrachten": "Considering systemically",
  "pragmatisch umsetzen": "Implementing pragmatically",
  "zukunftsorientiert denken": "Thinking future-orientated",
  "nachhaltig entwickeln": "Developing sustainably",
  "integrativ zusammenarbeiten": "Working together integratively",
  karriere: {
    Karriere: "Career",
    text: "We are always on the move – to be successful and run sustainable real estate projects. An attractive perspective? You can be part of it. If you feel the spirit, whether you are an engineer or business administrator, and want to spend your effort on sustainable real estate projects, send us your resume.We are looking forward to it!",
  },
  chameleons: {
    name: "Philipp Güther",
    title: "Founder, CEO, Chief Information Consultant",
    text_part1:
      "Philipp has more than twenty years of expertise in the building and real estate sectors. At the Technical University of Darmstadt and the Economic University of Vienna, he studied economic engineering. From Bauhaus-Universität Weimar, he later received his PhD in management tools for innovation in building enterprises.",
    text_part2:
      "He has had a variety of positions, including worldwide strategy consultant for the banking and real estate industries as well as innovation consultant for German companies. He also oversaw the HOCHTIEF PPP Solutions contract management team and provided advice to the Federation of the German Construction Industry.",
    text_part3:
      "In addition to being an authority in the field, Philipp is also a well-known lecturer and writer on the subject. He is certified as a scrum master, project manager, and BIM engineer. Outside of work, Philipp is a loving father to his three sons and enjoys taking on new challenges in outdoor activities. He is a capable consultant and leader due to the combination of his personal principles and professional skills. Using his extensive knowledge, he works with a reputable group of subject matter experts when necessary to see client projects through to completion.",
    text_part4:
      "These include Philipps engagement in the <1>Federal Initiative Impact Investing</1> to raise awareness of impact-oriented, ecological and social investing and to develop concrete solutions for the real estate sector.",
  },
};
