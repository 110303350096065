import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LanguageSwitcher } from "../../global/components/LanguageSwitcher";
import { PageTemplate } from "../../global/components/PageTemplate";
import { ToTop } from "../../global/components/ToTop";
import { sleep } from "../../util";
import { Footer } from "../home/components/Footer";
import { ContactButton } from "../home/components/startingSection/ContactButton";
import { BIM } from "./BIM";
import { Informationswert } from "./Informationswert";
import { Scrum } from "./Scrum";
import { StartingSection } from "./StartingSection";
import { Zitat } from "./Zitat";

export function Tools() {
  const cc = useRef<HTMLDivElement>(null);
  const bim = useRef<HTMLDivElement>(null);
  const scrum = useRef<HTMLDivElement>(null);

  const location = useLocation();

  useEffect(() => {
    async function scrollTo() {
      window.scrollTo(0, 0);
      await sleep(300);
      if (location.state === "cc") {
        cc.current?.scrollIntoView({ behavior: "smooth" });
      } else if (location.state === "bim") {
        bim.current?.scrollIntoView({ behavior: "smooth" });
      } else if (location.state === "scrum") {
        scrum.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    scrollTo();
  }, [location.state]);

  return (
    <>
      <PageTemplate>
        <StartingSection />
        <div ref={cc}>
          <Informationswert />
        </div>
        <Zitat />
        <div ref={bim}>
          <BIM />
        </div>
        <Scrum ref={scrum} />
        <ContactButton startingY={50} />
        <LanguageSwitcher />
        <ToTop />
        <Footer />
      </PageTemplate>
    </>
  );
}
