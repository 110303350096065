import { about_english } from "./english/about_english";
import { datenschutz_english } from "./english/datenschutz_english";
import { home_english } from "./english/home_english";
import { imprint_english } from "./english/imprint_english";
import { insights_english } from "./english/insights_english";
import { services_english } from "./english/services_english";
import { tools_english } from "./english/tools_english";

export const english = {
  translation: {
    home: home_english,
    about: about_english,
    services: services_english,
    tools: tools_english,
    imprint: imprint_english,
    privacy: datenschutz_english,
    insights: insights_english,
  },
};
