import { useTranslation } from "react-i18next";

export function Zitat() {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-darkPurple">
      <div className="flex justify-center w-full p-10 py-32 text-lightPurple md:px-24">
        <div className="text-3xl lg:w-2/3">
          {t("tools.citation")}
          <p className="mt-2 text-xl text-white">Philipp</p>
        </div>
      </div>
    </div>
  );
}
