import { Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Section } from "../Section";
import { Link } from "../startingSection/Link";
import { ImageAndContent } from "./ImageAndContent";

export function ToolsAndSystemsSection() {
  return (
    <Section>
      <div className="absolute top-3 sm:top-10 md:left-32 left-10">
        <Link href="/tools" variant="purple">
          Tools & systems
        </Link>
      </div>
      <Stack
        className="absolute max-w-[360px] xl:max-w-[500px] lg:flex hidden top-44 md:left-32 left-10 "
        spacing={6}
      >
        <ToolsAnleser />
      </Stack>
      <ImageAndContent />
    </Section>
  );
}

export function ToolsAnleser() {
  const { t } = useTranslation();
  return (
    <>
      <Text className="text-3xl lg:text-4xl text-darkPurple">
        {t("home.tools.Strategie-Konzepte und Management-Instrumente.")}
      </Text>
      <Text className="text-xl whitespace-pre-wrap text-darkPurple">
        {t("home.tools.Wissenschaftlich entwickelt,\npragmatisch eingesetzt.")}
      </Text>
    </>
  );
}
