import { useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";

export function Slogan() {
  const { ref: bgref } = useParallax({ speed: -10 });
  const { ref: sloganref } = useParallax({
    speed: 10,
  });

  const { t } = useTranslation();

  return (
    <>
      <div
        ref={bgref as React.RefObject<HTMLDivElement>}
        style={{
          background:
            "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
        }}
        className="absolute -mb-[25px] w-[45%] md:w-[calc(30%-8rem)] lg:w-[calc(28%-8rem)] h-[30%] sm:h-[50%] md:bottom-28 bottom-12 right-6 md:right-24  lg:right-32 bg-fullGreen"
      />
      <div
        ref={sloganref as React.RefObject<HTMLDivElement>}
        className="bg-[#F1EFF5] mb-[25px] md:bottom-40 bottom-24 z-20 lg:right-48 md:right-32 right-10 right text-lg xs:text-xl sm:text-3xl md:w-[45%] lg:w-[35%] w-[calc(100vw-200px)] absolute text-lightPurple p-5 sm:p-10"
      >
        <div className="relative w-full h-full">
          <div className="absolute md:flex hidden  origin-top-right  flex-row -top-[18px] -left-[200px]">
            <div className="w-[200px] h-[3px] bg-lightPurple"></div>
            <div className="w-[15px] h-[3px] rotate-90 -translate-x-[7px]  bg-lightPurple"></div>
          </div>
        </div>
        {t("home.slogan")}
      </div>
    </>
  );
}
