import mapPurple from "../../../assets/mapPurple.svg";
import { Section } from "../../home/components/Section";
import { TextSection } from "../../home/components/TextSection";

import { useMediaQuery } from "@mantine/hooks";
import { forwardRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import meeting from "../../../assets/Chameleon_Concepts_Meeting.jpg";

export const Beratung = forwardRef<HTMLDivElement>((_, ref) => {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: textref } = useParallax<HTMLDivElement>({ speed: lg ? 10 : 0 });
  const { ref: bgref } = useParallax<HTMLDivElement>({
    speed: lg ? -10 : 0,
  });

  const { t } = useTranslation();

  return (
    <Section>
      <div className="flex flex-col items-center w-full bg-darkPurple">
        <div className="flex justify-center w-full p-10 py-32 text-lightPurple md:px-24">
          <div className="text-3xl lg:w-1/2">
            {t("services.beratung.citation")}
            <p className="mt-2 text-xl text-white">Philipp</p>
          </div>
        </div>
        <img
          className=" lg:hidden max-h-[300px] w-full object-cover object-top"
          src={meeting}
          alt="meeting"
        />
        <div
          ref={ref}
          className="relative flex w-full py-10 max-w-7xl text-lightPurple lg:px-10"
        >
          <img
            className="absolute hidden lg:block right-10 top-32 max-w-[80%] max-h-[60%]"
            src={meeting}
            alt="meeting"
          />
          <div
            ref={bgref}
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            className=" absolute w-[30%] h-[400px] -top-5 right-1/2 bg-fullGreen"
          />
          <div
            ref={textref}
            className="relative lg:w-[550px] pt-32 sm:p-16 sm:pt-20 p-10 items-end flex flex-col bg-white"
          >
            <img
              src={mapPurple}
              className="w-[150px] absolute top-3 right-3"
              alt="Map Icon"
            />
            <TextSection title={t("services.beratung.Beratung")}>
              <Trans i18nKey="services.beratung.text"></Trans>
            </TextSection>
          </div>
        </div>
      </div>
    </Section>
  );
});
