export const tools_german = {
  startingSection:
    "Die Immobilienbranche ist ein komplexer Industriezweig. Für einen nachhaltig gewinnbringenden Bau und Betrieb Ihrer Immobilien sind fundierte Strategie-Konzepte und spezielle Management-Instrumente unverzichtbar. <br /> <br /> Unsere Instrumente und Methodik wurden wissenschaftlich entwickelt. Sie sind vielfach erprobt. Und werden pragmatisch eingesetzt.",
  iw: {
    title:
      "Chameleon Concepts – Informations\u00ADwertschöpfung für Ihr Portfolio",
    text: "Mit unseren – speziell für die Immobilienbranche entwickelten – Chameleon Concepts erschaffen wir ein <strong>informationsbasiertes Wertschöpfungs\u00ADsystem</strong>, das jede Ihrer Immobilien in ihrem Umfeld, aber auch Ihr Geschäftsmodell, vollständig und präzise abbildet. Es weist die <strong> technisch und wirtschaftlich notwendigen Informationen und Kennwerte </strong> und darüber hinaus alle <strong>ESG-relevanten Daten und Dokumente</strong> aus. So sorgt es nicht nur für eine <strong>saubere Dokumentation</strong>. Über den gesamten Lebenszyklus. Es erlaubt darüber hinaus einen <strong>Forecast</strong> Ihrer strategischen Entwicklungs\u00ADmöglichkeiten.",
    sc: {
      title:
        "Wir entfalten <strong>Ihr Wertschöpfungssystem in drei Dimensionen:</strong>",
      "1. Die Chameleon-Map": "1. Die Chameleon-Map",
      "präsentiert die Informationen – wie eine Landkarte – themenspezifisch zugeordnet und in Beziehung zueinander.":
        "präsentiert die Informationen – wie eine Landkarte – themenspezifisch zugeordnet und in Beziehung zueinander.",
      "2. Der Chameleon-Atlas": "2. Der Chameleon-Atlas",
      "bündelt alle erfassten Maps zu Ihrem Informations- und Wertschöpfungsraum.":
        "bündelt alle erfassten Maps zu Ihrem Informations- und Wertschöpfungsraum.",
      "3. Das Chameleon-Navi": "3. Das Chameleon-Navi",
      "führt Sie zu Ihrem Informations- und Wertschöpfungsziel. Es bietet Ihnen verschiedene „Routen“, um die jeweils erforderlichen Informationen zu gewinnen, zu kombinieren und weiterzuverarbeiten.":
        "führt Sie zu Ihrem Informations- und Wertschöpfungsziel. Es bietet Ihnen verschiedene „Routen“, um die jeweils erforderlichen Informationen zu gewinnen, zu kombinieren und weiterzuverarbeiten.",
    },
  },
  citation:
    "»Chameleon Concepts ist der Guide in Ihrer Immobilien\u00ADlandschaft. Wir kennen die kürzesten Wege, sichersten Brücken, schnellsten Abkürzungen – und haben alles im Gepäck, was unterwegs nötig ist. Gut sortiert und immer griffbereit.«",

  bim: {
    title: "BIM – immer auf dem aktuellen Stand",
    text: "Wir bauen mit Ihnen und für Sie Ihren Wertschöpfungsraum auf: Hierfür implementieren wir eine moderne und effiziente Organisationsmethode für nachhaltiges Informationsmanagement in Ihre Geschäftsprozesse: <strong>Building Information Modeling, kurz BIM</strong>. Das 3-dimensionale Gebäudemodell dient als strukturgebende Systematik für Kontextinformationen. Zugleich <strong>vernetzt</strong> dieses Informationsmanagement-System <strong>die Akteur:innen</strong> miteinander. Alle Beteiligten können die für sie jeweils relevanten Daten bereitstellen, einsehen und weiterverarbeiten. Das sorgt für eine <strong>aktuelle Datenlage</strong> und erlaubt einen <strong>reibungslosen</strong> und <strong>verlässlichen Informationsbeschaffungsprozess</strong>. Über den gesamten Lebenszyklus hinweg.",

    "Immer auf dem Stand aktuelle Informationen mit einem Klick":
      "Immer auf dem Stand: aktuelle Informationen mit einem Klick",
    "Umfassendes Informations\u00ADsystem –":
      "Umfassendes Informations\u00ADsystem –",
    "für das komplette Bauwerk und seinen gesamten Lebenszyklus":
      "für das komplette Bauwerk und seinen gesamten Lebenszyklus",
    "Einfache Informations\u00ADbeschaffung –":
      "Einfache Informations\u00ADbeschaffung –",
    "für alle Akteurinnen": "für alle Akteur:innen",
    "Durchgängiger Informations\u00ADfluss –":
      "Durchgängiger Informations\u00ADfluss –",
    "dank individuell aufgesetztem Informations\u00ADbeschaffungsprozess und optimal angepassten Informationsschnittstellen":
      "dank individuell aufgesetztem Informations\u00ADbeschaffungsprozess und optimal angepassten Informationsschnittstellen",
    "Transparente Nachhaltigkeits\u00ADdaten –":
      "Transparente Nachhaltigkeits\u00ADdaten –",
    "für zukunftssichere Managemententscheidungen":
      "für zukunftssichere Managemententscheidungen",
    "Effiziente Nachhaltigkeits\u00ADbericht\u00ADerstattung –":
      "Effiziente Nachhaltigkeits\u00ADbericht\u00ADerstattung –",
    "dank Building Information Modeling (BIM)":
      "dank Building Information Modeling (BIM)",
  },
  scrum: {
    title: "Scrum: die wirksame Kolla\u00ADborations\u00ADkultur",

    text: "Mit Scrum etablieren wir bei Ihnen eine zeitgemäße, <strong>agile Projektmanagement-Methode</strong>. Sie setzen das Ziel Ihres Entwicklungsprojekts. Den Weg dorthin gestaltet Ihr Projektteam in <strong>Eigenregie und Eigenverantwortung</strong>: In der selbstorganisierten Umsetzung entfalten Ihre Expert:innen ihr größtes Potenzial. Stellen sich unterwegs vorab unberücksichtigte Herausforderungen, entwickeln sie gemeinsam eine Lösung. Und setzen sie um. Dazu kommen sie in kurzen Abständen zusammen und stimmen ihre Zwischenergebnisse und Arbeitsschritte miteinander ab. So können sie schnell und <strong>kooperativ</strong> auf Veränderungen reagieren und gelangen <strong>in kurzen Sprints zum gemeinsamen Projektziel</strong>: dem profitablen Bau und Betrieb Ihrer Immobilien.",
  },
};
