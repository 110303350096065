import { Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import logobii from "../../../assets/Biii_Logo.svg";
import logokodex from "../../../assets/Kodex_Signet(1).svg";
import logo from "../../../assets/logouniohneclaim.svg";

import { Link } from "./startingSection/Link";

export function Footer({ current }: { current?: string }) {
  const { t } = useTranslation();
  return (
    <div
      style={{ minHeight: "calc(max(50vh, 400px))" }}
      className="relative flex bg-darkPurple"
    >
      <div
        style={{ minHeight: "calc(max(60vh, 400px))" }}
        className="flex flex-col justify-between w-full h-full px-10 pt-10 pb-5 sm:pb-10 lg:pl-14 lg:px-0 "
      >
        <Group className="justify-center w-full mt-8" spacing={24}>
          <Link
            href="/impressum"
            variant={current === "impressum" ? "fullgreen" : "purple"}
          >
            {t("home.footer.Impressum")}
          </Link>
          <Link
            href="/datenschutz"
            variant={current === "datenschutz" ? "fullgreen" : "purple"}
          >
            {t("home.footer.Datenschutz")}
          </Link>
        </Group>

        <Group spacing="lg">
          <img src={logo} className="w-[180px]" alt="logo" />

          <a
            href="https://bundesinitiative-impact-investing.de/"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer"
          >
            <img src={logobii} className="w-[180px]" alt="logoBII" />
          </a>
          <a
            href="https://icg-institut.de/de/social-impact-investing/kodex-fuer-social-impact-investing-werte-sinn-wirkung/"
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer"
          >
            <img src={logokodex} className="w-[100px]" alt="logoKodex" />
          </a>
        </Group>
      </div>
    </div>
  );
}
