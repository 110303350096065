import { about_german } from "./german/about_german";
import { datenschutz_german } from "./german/datenschutz_german";
import { home_german } from "./german/home_german";
import { imprint_german } from "./german/imprint_german";
import { insights_german } from "./german/insights_german";
import { services_german } from "./german/services_german";
import { tools_german } from "./german/tools_german";

export const german = {
  translation: {
    home: home_german,
    about: about_german,
    services: services_german,
    tools: tools_german,
    imprint: imprint_german,
    privacy: datenschutz_german,
    insights: insights_german,
  },
};
