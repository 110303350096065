import { Section } from "../Section";
import { TextSection } from "../TextSection";
import { BookaMark } from "./BookaMark";

import { ReactNode } from "react";
import compass from "../../../../assets/compass.svg";
import map from "../../../../assets/map.svg";
import mountains from "../../../../assets/mountains.svg";

import { Group } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import shoe from "../../../../assets/shoe.svg";

export function ServiceSection() {
  const { ref: balkenref } = useParallax<HTMLDivElement>({
    speed: 10,
  });

  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <>
      <Section>
        <BookaMark />

        <div className="flex flex-col items-center w-full py-10 space-y-8 lg:space-y-0 justify-evenly lg:flex-row">
          <div className="flex flex-col justify-center text-2xl lg:relative sm:text-3xl lg:text-4xl lg:pl-24 text-darkPurple lg:w-1/3">
            <p className="px-10 top-0 left-0 lg:absolute lg:w-[500px] lg:translate-x-32 lg:-translate-y-36 lg:mt-0 mt-44 lg:px-0  lg:mb-0">
              Chameleon Concepts – <br />{" "}
              {t("home.service.der Guide für Ihr Immobilien-Portfolio.")}
            </p>
            <p className="px-10 text-xl text-darkPurple  top-0 left-0 lg:absolute lg:w-[300px] xl:w-[350px] lg:-translate-y-8   lg:translate-x-32 mt-8 lg:mt-3  lg:px-0  lg:mb-0">
              {t(
                "home.service.Für Investoren, Kapitalgeber, Konzerne und öffentliche Institutionen."
              )}
            </p>
          </div>
          <div className="relative flex items-end w-full sm:px-8 lg:h-full lg:w-2/3">
            <p className="hidden lg:block text-xl  text-darkPurple font-bold absolute right-[45vw] whitespace-pre-line bottom-[22vw]">
              {t("home.service.Sie agieren\nESG-konform.")}
            </p>
            <p className="hidden lg:block text-xl  text-darkPurple font-bold absolute whitespace-pre-line right-[30vw] bottom-[24vw]">
              {t("home.service.Sie beherrschen\ndie Risiken.")}
            </p>

            <p className="hidden whitespace-pre-line lg:block text-xl  text-darkPurple font-bold absolute right-[13vw] bottom-[28vw]">
              {t("home.service.Sie maximieren\nIhre Profitabilität.")}
            </p>
            <img
              className="object-contain w-full font-bold lg:-mb-24"
              src={mountains}
              alt="Berge"
            />
          </div>
          <div className="flex flex-col w-full px-10 text-xl font-bold md:px-16 lg:hidden sm:text-2xl text-darkPurple">
            <Group className="items-start flex-nowrap">
              <p>{">"}</p>
              <p>{t("home.service.Sie agieren ESG-konform.")}</p>
            </Group>
            <Group className="items-start flex-nowrap">
              <p>{">"}</p>
              <p>{t("home.service.Sie beherrschen die Risiken.")}</p>
            </Group>
            <Group className="items-start flex-nowrap">
              <p>{">"}</p>
              <p>{t("home.service.Sie maximieren Ihre Profitabilität.")}</p>
            </Group>
          </div>
        </div>
      </Section>
      <Section>
        <div
          ref={balkenref}
          className=" hidden lg:flex absolute left-0 w-8 bg-lightGreen h-[600px] -top-[300px]"
        />
        <div
          style={{ minHeight: "calc(max(100vh, 800px))" }}
          className="flex flex-col items-center w-full p-8 space-y-8 lg:p-12 lg:justify-evenly lg:space-y-0 lg:flex-row"
        >
          <TextWithIcon
            onClick={() => navigate("/services", { state: "beratung" })}
            title={t("home.service.Beratung")}
            icon={map}
          >
            {t("home.service.beratung_text")}
          </TextWithIcon>
          <TextWithIcon
            onClick={() =>
              navigate("/services", { state: "projektmanagement" })
            }
            title={t("home.service.Projektmanagement")}
            icon={compass}
          >
            {t("home.service.projektmanagement_text")}
          </TextWithIcon>
          <TextWithIcon
            onClick={() => navigate("/services", { state: "schulung" })}
            title={t("home.service.Schulung")}
            icon={shoe}
          >
            {t("home.service.schulung_text")}
          </TextWithIcon>
        </div>
      </Section>
    </>
  );
}

function TextWithIcon({
  children,
  title,
  icon,
  onClick,
}: {
  children: ReactNode;
  title: string;
  icon: string;
  onClick: () => void;
}) {
  const { hovered, ref } = useHover();
  return (
    <div
      ref={ref}
      onClick={onClick}
      className="flex cursor-pointer group lg:max-w-[450px] flex-col mx-3 items-center justify-between w-full lg:min-h-full xl:h-[85%] lg:pt-[70px] sm:p-16 p-10 lg:w-1/3  bg-darkPurple"
    >
      <TextSection color="light" hovered={hovered} title={title}>
        {children}
      </TextSection>
      <img src={icon} className="w-[150px]" alt="section icon" />
    </div>
  );
}
