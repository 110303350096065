import { Group, Stack } from "@mantine/core";
import { useHover, useMediaQuery } from "@mantine/hooks";
import { useParallax } from "react-scroll-parallax";
import compass from "../../../../assets/compassandx.svg";
import toolsAndSystems from "../../../../assets/tools.svg";

import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TextSection } from "../TextSection";
import { ToolsAnleser } from "./ToolsAndSystemsSection";

export function ImageAndContent() {
  const navigate = useNavigate();
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: bgref } = useParallax({ speed: lg ? -10 : 0 });
  const { ref: contentref } = useParallax({ speed: lg ? 10 : 0 });

  const { t } = useTranslation();

  return (
    <div
      style={{ minHeight: "calc(max(100vh, 800px))" }}
      className="flex flex-col items-center w-full p-10 pb-0 justify-evenly tools:pl-10 lg:flex-row"
    >
      <Stack
        spacing="xl"
        className="lg:w-[60%] w-full lg:max-w-2xl lg:mr-3  my-20"
      >
        <Group
          spacing="xl"
          className="items-start justify-between w-full mt-10 lg:justify-end flex-nowrap"
        >
          <Stack className="lg:hidden  md:pl-[5.5rem]" spacing={6}>
            <ToolsAnleser />
          </Stack>
          <img
            className="md:max-w-[150px] max-w-[100px] ml-4"
            src={compass}
            alt="compass"
          />
        </Group>
        <img src={toolsAndSystems} alt="tools and systems" />
      </Stack>
      <div className="lg:w-[40%]  flex justify-center">
        <div className="relative lg:max-w-md">
          <div
            ref={bgref as React.RefObject<HTMLDivElement>}
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            className="absolute w-[50%] -z-10 h-[80%] -top-7 -right-7 "
          />
          <div
            ref={contentref as React.RefObject<HTMLDivElement>}
            className="flex flex-col p-10 mr-8 md:p-16 justify-evenly bg-lightGreen"
          >
            <div>
              <TextSectionWithHover
                onClick={() => navigate("/tools", { state: "cc" })}
                title="Chameleon Concepts"
              >
                {t("home.tools.cc")}
              </TextSectionWithHover>
            </div>
            <TextSectionWithHover
              onClick={() => navigate("/tools", { state: "bim" })}
              title="BIM"
            >
              {t("home.tools.bim")}
            </TextSectionWithHover>
            <TextSectionWithHover
              onClick={() => navigate("/tools", { state: "scrum" })}
              title="Scrum"
            >
              {t("home.tools.scrum")}
            </TextSectionWithHover>
          </div>
        </div>
      </div>
    </div>
  );
}

function TextSectionWithHover({
  title,
  children,
  onClick,
}: {
  children: ReactNode;
  title: string;
  onClick: () => void;
}) {
  const { hovered, ref } = useHover();

  return (
    <div onClick={onClick} className="mb-16 cursor-pointer" ref={ref}>
      <TextSection hoveredColor="lightPurple" hovered={hovered} title={title}>
        {children}
      </TextSection>
    </div>
  );
}
