import { useMediaQuery } from "@mantine/hooks";
import { Trans, useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import workspace from "../../assets/philipp.jpg";
import { TextSection } from "../home/components/TextSection";

export function Chameleons() {
  const xl = useMediaQuery("(min-width: 1300px)");

  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref: textref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });
  const { ref: bgref } = useParallax<HTMLDivElement>({
    speed: lg ? -10 : 0,
  });

  const { t } = useTranslation();

  return (
    <div className="relative flex justify-center">
      <div className="flex flex-col items-center w-full h-full">
        <img
          style={{
            objectPosition: "top 0px right 0px",
          }}
          className=" lg:hidden max-h-[400px]  w-full object-cover "
          src={workspace}
          alt="meeting"
        />
        <div className="relative pt-10 lg:py-10 w-full lg:min-h-[800px] flex items-center justify-start lg:px-24">
          <div
            style={{
              background:
                "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
            }}
            ref={bgref}
            className="  absolute w-[150px] lg:h-[500px] h-[200px] lg:-top-32 -top-10 left-14 bg-fullGreen"
          />
          <img
            style={{
              objectPosition: `top 0px right ${xl ? "-200px" : "-400px"}`,
            }}
            className="absolute hidden lg:block right-0 top-0 -z-10 max-w-[50%] lg:min-w-[40%] object-left object-cover h-full"
            src={workspace}
            alt="workspace"
          />
          <div
            ref={textref}
            className="relative bg-[#F0EDF4] lg:w-[70%]  sm:p-16 p-10 items-end flex flex-col "
          >
            <TextSection title={t("about.chameleons.name")}>
              <p className="">
                <b>{t("about.chameleons.title")}</b>
                <br />
                <br />
                {t("about.chameleons.text_part1")} <br /> <br />
                {t("about.chameleons.text_part2")}
                <br /> <br /> {t("about.chameleons.text_part3")}
                <br /> <br />
                <Trans i18nKey="about.chameleons.text_part4">
                  Dazu engagiert sich Philipp auch aktiv in der
                  <a
                    className="font-bold cursor-pointer"
                    href="https://bundesinitiative-impact-investing.de/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bundesinitiative Impact Investing
                  </a>
                  e.V., um Bewusstsein für wirkungsorientiertes, ökologisches
                  und soziales Investieren zu schaffen und konkrete
                  Lösungsansätze für den Immobiliensektor zu erarbeiten.
                </Trans>
              </p>
            </TextSection>
          </div>
        </div>
      </div>
    </div>
  );
}
