import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";
import desk from "../../assets/werte_spirit.svg";
import { LanguageSwitcher } from "../../global/components/LanguageSwitcher";
import { PageTemplate } from "../../global/components/PageTemplate";
import { ToTop } from "../../global/components/ToTop";
import { Footer } from "../home/components/Footer";
import { Section } from "../home/components/Section";
import { TextSection } from "../home/components/TextSection";
import { ContactButton } from "../home/components/startingSection/ContactButton";
import { Chameleons } from "./Chameleons";
import { Karriere } from "./Karriere";
import { NavbarSection } from "./NavbarSection";

export function About() {
  const lg = useMediaQuery("(min-width: 1024px)");

  const { ref } = useParallax<HTMLDivElement>({
    speed: lg ? 10 : 0,
  });

  const { t } = useTranslation();

  return (
    <>
      <PageTemplate>
        <NavbarSection />
        <Section>
          <div className="w-full flex justify-center bg-[#F0EDF4]">
            <div className="relative flex flex-col items-center justify-center w-full mb-16 lg:mb-0 lg:flex-row max-w-[1400px] lg:p-10">
              <div className=" bg-[#F0EDF4] hidden lg:flex lg:bg-transparent z-30 w-full p-10 md:p-24 lg:p-0 lg:pr-10 right-0 top-24 lg:absolute lg:mb-0 mb-5  justify-end text-lightPurple ">
                <div className="text-3xl lg:w-1/2">
                  {t("about.intro")}
                  <p className="mt-2 text-xl text-darkPurple">Philipp</p>
                </div>
              </div>

              <div className="relative  lg:w-[500px] h-fit lg:h-[100%] lg:pb-24 sm:p-16 p-10 lg:px-10 xl:px-16 justify-center flex flex-col lg:-translate-y-20 bg-[#9D89B9]">
                <div
                  style={{
                    background:
                      "repeating-linear-gradient(-45deg,#94E026, #94E026 3px, #00000000 1px, #00000000 18px)",
                  }}
                  className=" hidden lg:block absolute lg:w-[50%] w-[40%] h-[450px]  -bottom-80 left-1/4 bg-fullGreen"
                />
                <div
                  ref={ref}
                  className="py-12 px-10 z-20 w-[400px] -bottom-32 text-fullGreen absolute hidden lg:block font-sans -right-32 text-lg bg-darkPurple"
                >
                  <b>{t("about.Was die Chameleons anders machen")}</b>
                  <br />
                  <br />
                  {[
                    t("about.systemisch betrachten"),
                    t("about.pragmatisch umsetzen"),
                    t("about.zukunftsorientiert denken"),
                    t("about.nachhaltig entwickeln"),
                    t("about.integrativ zusammenarbeiten"),
                  ].map((item) => (
                    <p className="mb-2">{`> ${item}`}</p>
                  ))}
                </div>
                <TextSection title={t("about.Der Chameleon-Spirit")}>
                  {t("about.spirit")}
                </TextSection>
              </div>
              <div className="w-full p-10 font-sans text-lg lg:mt-3 sm:p-16 text-fullGreen lg:hidden bg-darkPurple">
                <b>{t("about.Was die Chameleons anders machen")}:</b>
                <br />
                <br />
                {[
                  t("about.systemisch betrachten"),
                  t("about.pragmatisch umsetzen"),
                  t("about.zukunftsorientiert denken"),
                  t("about.nachhaltig entwickeln"),
                  t("about.integrativ zusammenarbeiten"),
                ].map((item) => (
                  <p className="mb-2">{`> ${item}`}</p>
                ))}
              </div>
              <div className=" bg-[#F0EDF4]  lg:hidden flex lg:bg-transparent z-30 w-full p-10 md:p-24 md:pb-0 mb-5 right-0 top-14 pb-0 justify-end text-lightPurple ">
                <div className="text-3xl lg:w-1/2">
                  {t("about.intro")}
                  <p className="mt-2 text-xl text-darkPurple">Philipp</p>
                </div>
              </div>
              <img
                src={desk}
                className="lg:max-w-[50%]  lg:translate-x-10 lg:ml-20 lg:mt-56 max-w-[min(100%,500px)]"
                alt="workspace scg"
              />
            </div>
          </div>
        </Section>
        <Karriere />
        <Chameleons />
        <ContactButton startingY={50} />
        <ToTop />
        <LanguageSwitcher />
        <Footer />
      </PageTemplate>
    </>
  );
}
